import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ECharts, EChartsOption } from 'echarts';
import { snakeCaseToTitleCase } from 'src/app/common/utilities/display-helpers';
import { ChartType } from '../../../enums/chart-type.enum';
import {
  PhasesProgressAPIResponse,
  PlanDashboardItemAPIResponse,
} from '../../../types/responses/plan.responses';
import { DetailedPieData } from '../../reports/report-controller/series-helper';

@Component({
  selector: 'app-phase-plan-status-card',
  templateUrl: './phase-plan-status-card.component.html',
  styleUrl: './phase-plan-status-card.component.scss',
})
export class PhasePlanStatusCardComponent implements OnChanges {
  @Input() dashboardInfo: PlanDashboardItemAPIResponse;

  phaseData: DetailedPieData[] = [];

  planData: { name: string; value: number }[] = [];

  eChartsInstancePhase: ECharts;

  eChartsInstancePlan: ECharts;

  chartOptionsPhase: EChartsOption;

  chartOptionsPlan: EChartsOption;

  eChartType = ChartType.Pie;

  hasPhaseData = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dashboardInfo'] && this.dashboardInfo) {
      // phase data
      let totalProgress = 0;
      Object.keys(this.dashboardInfo.plan_status).forEach((key) => {
        totalProgress +=
          this.dashboardInfo.plan_status[
            key as keyof PhasesProgressAPIResponse
          ];
        this.phaseData.push({
          name: snakeCaseToTitleCase(key),
          value:
            this.dashboardInfo.plan_status[
              key as keyof PhasesProgressAPIResponse
            ],
          groupId: 'phase_status',
          labelId: key,
        });
      });
      if (!totalProgress) {
        this.hasPhaseData = false;
        this.phaseData = [];
      }
      // plan data
      const completed = Math.round(
        (this.dashboardInfo.plan_status.completed +
          this.dashboardInfo.plan_status.canceled) *
          100
      );
      this.planData.push({ name: 'Completed', value: completed });
      this.planData.push({ name: 'Not Completed', value: 100 - completed });
      this.setChartOptions();
    }
  }

  onChartInitPhase(e: ECharts) {
    this.eChartsInstancePhase = e;
  }

  onChartInitPlan(e: ECharts) {
    this.eChartsInstancePlan = e;
  }

  setChartOptions() {
    this.chartOptionsPlan = {
      tooltip: {
        trigger: 'item',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: (params: any) =>
          `${params.name}: ${Math.round(params.percent)}%`,
        textStyle: { color: 'black', fontFamily: 'greycliff-cf' },
        show: this.hasPhaseData,
      },
      legend: {
        show: false,
      },
      color: ['#27004b', '#f0eee9'],
      series: [
        {
          name: `${this.dashboardInfo.phase_displayname} Progress`,
          type: 'pie',
          radius: ['57%', '85%'],
          center: ['50%', '70%'],
          startAngle: 180,
          endAngle: 360,
          avoidLabelOverlap: true,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          emphasis: { disabled: true },
          data: this.planData,
        },
      ],
      graphic: [
        {
          type: 'text',
          right: 'center',
          top: '65%',
          style: {
            text: 'Plan Progress',
            fill: '#000',
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: 'greycliff-cf',
          },
          cursor: 'default',
        },
        {
          type: 'text',
          right: 'center',
          top: '52%',
          style: {
            text: `${this.planData[0].value}%`,
            fill: '#000',
            fontSize: '30px',
            fontWeight: 'bold',
            fontFamily: 'greycliff-cf',
          },
          cursor: 'default',
        },
      ],
    };

    this.chartOptionsPhase = {
      tooltip: {
        trigger: 'item',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: (params: any) =>
          `${params.seriesName} <br/>${params.name}: ${Math.round(
            params.percent
          )}%`,
        textStyle: { color: 'black', fontFamily: 'greycliff-cf' },
      },
      legend: {
        orient: 'vertical',
        top: 'center',
        right: 'right',
        icon: 'circle',
        textStyle: { color: 'black', fontFamily: 'greycliff-cf' },
        show: this.hasPhaseData,
      },
      color: ['#21b0ff', '#af4f9d', '#f0eee9', '#fcb638', '#d95195', '#27004b'],
      series: [
        {
          name: 'Plan Status',
          type: 'pie',
          radius: ['42%', '70%'],
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            disabled: true,
          },
          data: this.phaseData,
        },
      ],
      graphic: {
        type: 'text',
        right: 'center',
        top: 'center',
        style: {
          text: 'Plan Status',
          fill: '#000',
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: 'greycliff-cf',
        },
        cursor: 'default',
      },
    };
  }
}
