import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { UserService } from 'src/app/common/state/user/user.service';
import { userFromDTO } from 'src/app/common/state/user/user.utilities';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import {
  ResourceFiltersDTO,
  ResourceFolderModelDTO,
  ResourceListItemDTO,
} from '../../../dtos/resources.dto';
import { ResourcesBookmarkService } from '../../../services/resources/resources-bookmark.service';

@Component({
  selector: 'app-shared-folder',
  templateUrl: './shared-folder.component.html',
  styleUrl: './shared-folder.component.scss',
})
export class SharedFolderComponent implements OnInit {
  subs: Subscription[] = [];

  folderOwnerId: string | null;

  resourceFilters = {} as ResourceFiltersDTO;

  folderListMeta: PaginationDTO;

  folderId: string | null;

  folderOwner: UserLiteDTO;

  folder: ResourceFolderModelDTO | null = null;

  folderItems: ResourceListItemDTO[] | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private resourcesBookmarkService: ResourcesBookmarkService
  ) {
    this.folderOwnerId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.folderId = this.route.snapshot.paramMap.get('folderId');

    if (this.folderOwnerId) {
      this.resourceFilters.user_id = this.folderOwnerId;
      this.userService
        .fetchUserById(parseInt(this.folderOwnerId))
        .subscribe((userDTO) => {
          this.folderOwner = userFromDTO(userDTO);
          this.getFolder(this.folderOwnerId, this.folderId);
        });
    }

    // if URL changes, refetch the folder
    this.subs.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.folderId = this.route.snapshot.paramMap.get('folderId');
          this.getFolder(this.folderOwnerId, this.folderId);
        }
      })
    );
  }

  getFolder(folderUserId: string | null, folderId: string | null) {
    if (folderUserId && folderId) {
      this.resourcesBookmarkService
        .getUsersFolder(folderId, folderUserId)
        .subscribe((result) => {
          if (result) {
            this.folder = result.item;
            this.getFolderItems();
          }
        });
    }
  }

  selectFolder(newfolderId: number) {
    this.router.navigate([
      `/resources/shared/${this.folderOwnerId}/folder/${newfolderId}`,
    ]);
    this.folder = null;
  }

  getFolderItems() {
    this.folderItems = null;
    if (this.folderId) {
      this.resourcesBookmarkService
        .getUsersFolderItems(this.folderId, this.resourceFilters)
        .subscribe((result) => {
          if (result.items) {
            this.folderItems = result.items;
            this.folderListMeta = result._meta;
          } else {
            this.folderItems = [];
          }
        });
    }
  }

  paginationChange() {
    if (this.resourceFilters) {
      this.resourceFilters.page = this.folderListMeta.currentPage;
      this.resourceFilters.per_page = this.folderListMeta.perPage;
      this.getFolderItems();
    }
  }
}
