import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { CreateEditPhaseModalComponent } from '../../shared/components/modals/create-edit-phase-modal/create-edit-phase-modal.component';
import { CreateDeliverableModalComponent } from '../../shared/components/plans/create-deliverable-modal/create-deliverable-modal.component';
import { EditActionItemModalComponent } from '../../shared/components/plans/edit-action-item-modal/edit-action-item-modal.component';
import { EditDeliverableModalComponent } from '../../shared/components/plans/edit-deliverable-modal/edit-deliverable-modal.component';
import { PlansSortingService } from '../../shared/services/plans/plans-sorting.service';
import { PlansService } from '../../shared/services/plans/plans.service';
import {
  CURRENT_SCHOOL_YEAR,
  getSchoolYearListWithFutures,
} from '../../shared/services/school-year/school-year.utilities';
import {
  EditActionItem,
  EditDeliverable,
  EditPhase,
  FetchPlanDetails,
  ToggleDeliverableExpandedState,
  TogglePhaseExpandedState,
} from '../../shared/state/implementation-plan/implementation-plan.actions';
import {
  ActionItemStateModel,
  DeliverableStateModel,
  ImplementationPlanState,
  PhaseStateModel,
  PlanDetailsItemStateModel,
} from '../../shared/state/implementation-plan/implementation-plan.state';
import {
  ActionItemAPIResponse,
  DeliverableAPIResponse,
  PhaseAPIResponse,
  StatusType,
} from '../../shared/types/responses/plan.responses';
import { checkIfPlanEditor } from '../plans-page/plans-page-helpers';

@Component({
  selector: 'app-implementation-plan-details-page',
  templateUrl: './implementation-plan-details-page.component.html',
  styleUrl: './implementation-plan-details-page.component.scss',
})
export class ImplementationPlanDetailsPageComponent
  implements OnInit, OnDestroy
{
  @ViewChild('createEditPhaseModal')
  createEditPhaseModal: CreateEditPhaseModalComponent;

  @ViewChild('createDeliverableParentModal')
  createDeliverableParentModal: CreateDeliverableModalComponent;

  @ViewChild('editDeliverableParentModal')
  editDeliverableParentModal: EditDeliverableModalComponent;

  @ViewChild('editActionItemModal')
  editActionItemModal: EditActionItemModalComponent;

  user: User;

  isE2L = false;

  planId: number;

  planDetails$ = select(ImplementationPlanState.getPlanDetails);

  planDetails: PlanDetailsItemStateModel;

  currentPhaseId: number;

  currentDeliverable: DeliverableAPIResponse;

  currentActionItem: ActionItemAPIResponse;

  subs: Subscription[] = [];

  planPermissions = [];

  planEditor = false;

  planSchoolYearList: SchoolYearDTO[] = [];

  selectedSchoolYear: SchoolYearDTO | null = null;

  actionItemsHiddenMessage: string;

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private plansService: PlansService,
    private plansSortingService: PlansSortingService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['planId']) {
        this.planId = parseInt(params['planId']);
        this.store.dispatch(new FetchPlanDetails(this.planId));

        let modalOpenedFromUrl = false;

        this.planDetails$
          .pipe(takeUntil(this.destroy$))
          .subscribe((details) => {
            if (details) {
              this.planDetails = deepCopy(details);
              this.planDetails.start_date = this.formatDate(
                this.planDetails.start_date
              );
              this.planDetails.end_date = this.formatDate(
                this.planDetails.end_date
              );

              // update current Deliverable for editing
              if (this.currentDeliverable && this.currentPhaseId) {
                this.currentDeliverable = this.findDeliverable(
                  this.currentPhaseId,
                  this.currentDeliverable.id
                );
                // update current Action Item for editing
                if (this.currentActionItem) {
                  this.currentActionItem = this.findActionItem(
                    this.currentPhaseId,
                    this.currentDeliverable.id,
                    this.currentActionItem.id
                  );
                }
              }

              if (!modalOpenedFromUrl && this.planDetails.phase_displayname) {
                modalOpenedFromUrl = true;
                setTimeout(() => {
                  this.handleQueryParams();
                });
              }

              this.actionItemsHiddenMessage = `There are additional ${details.actionitem_displayname}(s) that do not match the chosen filter`;

              this.getPlanSchoolYears();
            }
          });
      }
    });

    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    if (this.user) {
      this.planEditor = checkIfPlanEditor(this.user);
      this.isE2L = checkIfE2L(this.user);
    }
  }

  handleQueryParams() {
    this.route.queryParams.subscribe((queryParams) => {
      const deliverableId = queryParams['deliverable_id'];
      const actionItemId = queryParams['action_item_id'];

      if (this.planDetails?.phases) {
        if (deliverableId) {
          this.planDetails.phases.forEach((phase) => {
            phase.deliverables.forEach((deliverable) => {
              if (
                deliverable.id === parseInt(deliverableId) &&
                this.editDeliverableParentModal
              ) {
                this.openEditDeliverableModal(deliverable.id, phase.id);
              }
            });
          });
        }

        if (actionItemId) {
          this.planDetails.phases.forEach((phase) => {
            phase.deliverables.forEach((deliverable) => {
              deliverable.actionItems.forEach((actionItem) => {
                if (
                  actionItem.id === parseInt(actionItemId) &&
                  this.editActionItemModal
                ) {
                  this.openEditActionItemModal(
                    actionItem.id,
                    deliverable.id,
                    phase.id
                  );
                }
              });
            });
          });
        }
      }
    });
  }

  findDeliverable(phaseId: number, delId: number): DeliverableAPIResponse {
    const phase = this.planDetails.phases.filter(
      (phaseItem) => phaseItem.id === phaseId
    );
    const del = phase[0].deliverables.filter((deliv) => deliv.id === delId);
    return del[0];
  }

  findActionItem(
    phaseId: number,
    delId: number,
    actionItemId: number
  ): ActionItemAPIResponse {
    const phase = this.planDetails.phases.filter(
      (phaseItem) => phaseItem.id === phaseId
    );
    const del = phase[0].deliverables.filter((deliv) => deliv.id === delId);
    const actionItem = del[0].actionItems.filter(
      (item) => item.id === actionItemId
    );
    return actionItem[0];
  }

  /* eslint-disable-next-line class-methods-use-this */
  formatDate(date: string) {
    // date comes in YYYY-MM-DD format
    const dates = date.split('-');
    return `${dates[1]}/${dates[2]}/${dates[0]}`;
  }

  /* eslint-disable-next-line class-methods-use-this */
  formatProgress(progress: number) {
    return Math.round(progress * 10000) / 100;
  }

  togglePhase(phaseId: number) {
    // Timeout so that collapse animation can finish before toggle state is updated
    setTimeout(() => {
      this.store.dispatch(new TogglePhaseExpandedState({ phaseId }));
    }, 500);
  }

  toggleDeliverable(deliverableId: number) {
    // Timeout so that collapse animation can finish before toggle state is updated
    setTimeout(() => {
      this.store.dispatch(
        new ToggleDeliverableExpandedState({ deliverableId })
      );
    }, 500);
  }

  isPhaseExpanded(phaseId: number): boolean {
    const phase = this.planDetails?.phases.find((p) => p.id === phaseId);
    return phase?.isExpanded ?? false;
  }

  isDeliverableExpanded(deliverableId: number): boolean {
    const deliverable = this.planDetails?.phases
      .flatMap((p) => p.deliverables)
      .find((d) => d.id === deliverableId);
    return deliverable?.isExpanded ?? false;
  }

  openCreatePhaseModal() {
    this.createEditPhaseModal.isEditMode = false;
    this.createEditPhaseModal.modal.config.titleText = `Create ${this.planDetails?.phase_displayname}`;
    this.createEditPhaseModal.phaseToEdit = undefined;
    this.createEditPhaseModal.modal.open();
  }

  openEditPhaseModal(phase: PhaseAPIResponse) {
    this.createEditPhaseModal.isEditMode = true;
    this.createEditPhaseModal.modal.config.titleText = `Edit ${this.planDetails?.phase_displayname}`;
    this.createEditPhaseModal.phaseToEdit = phase;
    this.createEditPhaseModal.modal.open();
  }

  onPhaseStatusChange(phaseId: number, status: StatusType) {
    this.plansService.updatePhaseStatus(phaseId, status).subscribe(() => {
      this.store.dispatch(new EditPhase({ phase: { id: phaseId, status } }));
    });
  }

  onDeliverableStatusChange(deliverableId: number, status: StatusType) {
    this.plansService
      .updateDeliverableStatus(deliverableId, status)
      .subscribe(() => {
        this.store.dispatch(new EditDeliverable({ id: deliverableId, status }));
      });
  }

  onActionItemStatusChange(actionItemId: number, status: StatusType) {
    this.plansService
      .updateActionItemStatus(actionItemId, status)
      .subscribe(() => {
        this.store.dispatch(new EditActionItem({ id: actionItemId, status }));
      });
  }

  openCreateDeliverableModal(phaseId: number) {
    this.createDeliverableParentModal.createDeliverableModal.config.titleText = `New ${this.planDetails?.deliverable_displayname}`;
    this.createDeliverableParentModal.phaseId = phaseId;
    this.createDeliverableParentModal.createDeliverableModal.open();
  }

  openEditDeliverableModal(deliverableId: number, phaseId: number) {
    this.currentDeliverable = this.findDeliverable(phaseId, deliverableId);
    this.currentPhaseId = phaseId;
    this.editDeliverableParentModal.editDeliverableModal.open();
  }

  openEditActionItemModal(
    actionItemId: number,
    deliverableId: number,
    phaseId: number
  ) {
    this.currentActionItem = this.findActionItem(
      phaseId,
      deliverableId,
      actionItemId
    );
    this.currentDeliverable = this.findDeliverable(phaseId, deliverableId);
    this.currentPhaseId = phaseId;
    this.editActionItemModal.openModal();
  }

  dropPhase(phase: PhaseStateModel[], event: CdkDragDrop<PhaseStateModel[]>) {
    const sortData = this.plansSortingService.calcSortChange(
      phase,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(phase, event.previousIndex, event.currentIndex);
    if (sortData) {
      this.plansSortingService
        .sortPhase(sortData.id, sortData.direction, sortData.refId)
        .subscribe((response) => {
          if (response) {
            this.store.dispatch(
              new EditPhase({
                phase: {
                  id: response.item.id,
                  sort_order: response.item.sort_order,
                },
              })
            );
          }
        });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, class-methods-use-this
  dropDeliverable(
    deliverable: DeliverableStateModel[],
    event: CdkDragDrop<DeliverableStateModel[]>
  ) {
    const sortData = this.plansSortingService.calcSortChange(
      deliverable,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(deliverable, event.previousIndex, event.currentIndex);
    if (sortData) {
      this.plansSortingService
        .sortDeliverable(sortData.id, sortData.direction, sortData.refId)
        .subscribe((response) => {
          if (response) {
            this.store.dispatch(
              new EditDeliverable({
                id: response.item.id,
                sort_order: response.item.sort_order,
              })
            );
          }
        });
    }
  }

  dropActionItem(
    deliverable: ActionItemStateModel[],
    event: CdkDragDrop<ActionItemStateModel[]>
  ) {
    const sortData = this.plansSortingService.calcSortChange(
      deliverable,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(deliverable, event.previousIndex, event.currentIndex);
    if (sortData) {
      this.plansSortingService
        .sortActionItem(sortData.id, sortData.direction, sortData.refId)
        .subscribe((response) => {
          if (response) {
            this.store.dispatch(
              new EditActionItem({
                id: response.item.id,
                sort_order: response.item.sort_order,
              })
            );
          }
        });
    }
  }

  getPlanSchoolYears() {
    // eslint-disable-next-line prefer-const
    let [planStartMonth, planStartDay, planStartYear] =
      this.planDetails.start_date.split('/').map(Number);
    const [planEndMonth, planEndDay, planEndYear] = this.planDetails.end_date
      .split('/')
      .map(Number);
    const currentSchoolYear = Number(CURRENT_SCHOOL_YEAR.name.slice(-4));
    let futureYears = 0;
    if (planEndYear >= currentSchoolYear) {
      if (planEndMonth <= 6 && planEndDay <= 30) {
        futureYears = planEndYear - currentSchoolYear;
      } else {
        futureYears = planEndYear - currentSchoolYear + 1;
      }
    }
    if (planStartMonth >= 7 && planStartDay >= 1) {
      planStartYear += 1;
    }
    this.planSchoolYearList = getSchoolYearListWithFutures(
      futureYears,
      planStartYear
    );
  }

  // eslint-disable-next-line class-methods-use-this
  checkDeliverableHidden(phase: PhaseStateModel) {
    const deliverableHidden = phase.deliverables.some(
      (deliverable) => deliverable.hidden
    );
    const actionItemHidden = phase.deliverables.some((deliverable) =>
      this.checkActionItemHidden(deliverable)
    );

    if (deliverableHidden) {
      if (actionItemHidden) {
        return `There are additional ${this.planDetails.deliverable_displayname}(s) and ${this.planDetails.actionitem_displayname}(s) that do not match the chosen filter`;
      }
      return `There are additional ${this.planDetails.deliverable_displayname}(s) that do not match the chosen filter`;
    }

    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  checkActionItemHidden(deliverable: DeliverableStateModel) {
    return deliverable.actionItems.some((actionItem) => actionItem.hidden)
      ? `There are additional ${this.planDetails.actionitem_displayname}(s) that do not match the chosen filter`
      : '';
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
