import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserType } from 'src/app/common/state/user/type/user-type';
import { PrivateRoute } from 'src/app/common/types/routes';
import { CoacheeListPageComponent } from 'src/app/private/pages/coachee-list-page/coachee-list-page.component';
import {
  AUTH_GUARDED_ROUTE,
  AUTH_GUARDED_ROUTE_COACH,
  AUTH_GUARDED_ROUTE_OPERATOR,
  AUTH_ROUTE_GUARDED_ROUTE,
  AUTH_ROUTE_GUARDED_ROUTE_E2L_EMPLOYEE,
  AUTH_ROUTE_GUARDED_ROUTE_E2L_EMPLOYEE_COACHES,
  AUTH_ROUTE_GUARDED_ROUTE_OPERATOR,
} from '../../common/utilities/route.helpers';
import { AddDistrictComponent } from '../pages/add-district/add-district.component';
import { AllCelebrationsPageComponent } from '../pages/all-celebrations-page/all-celebrations-page.component';
import { CoachFeedbackPageComponent } from '../pages/coach-feedback-page/coach-feedback-page.component';
import { CoachListPageComponent } from '../pages/coach-list-page/coach-list-page.component';
import { CoacheeEvidencePageComponent } from '../pages/coachee-evidence-page/coachee-evidence-page.component';
import { CoachingCompetenciesPageComponent } from '../pages/coaching-competencies-page/coaching-competencies-page.component';
import { CoachingLogInfoPageComponent } from '../pages/coaching-log-info-page/coaching-log-info-page.component';
import { CoachingLogPageComponent } from '../pages/coaching-log-page/coaching-log-page.component';
import { CohortsPageComponent } from '../pages/cohorts-page/cohorts-page.component';
import { ConfigurationPageComponent } from '../pages/configuration-page/configuration-page.component';
import { CoachDashboardComponent } from '../pages/dashboard/coach-dashboard/coach-dashboard.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { LeaderDashboardComponent } from '../pages/dashboard/leader-dashboard/leader-dashboard.component';
import { LearnerDashboardComponent } from '../pages/dashboard/learner-dashboard/learner-dashboard.component';
import { EditDistrictComponent } from '../pages/edit-district/edit-district.component';
import { CreateFormPageComponent } from '../pages/forms/create-form-page/create-form-page.component';
import { EditFormPageComponent } from '../pages/forms/edit-form-page/edit-form-page.component';
import { FormsListPageComponent } from '../pages/forms/forms-list-page/forms-list-page.component';
import { GeneralLogInfoPageComponent } from '../pages/general/general-log-info-page/general-log-info-page.component';
import { GeneralLogPageComponent } from '../pages/general/general-log-page/general-log-page.component';
import { ImplementationPlanDetailsPageComponent } from '../pages/implementation-plan-details-page/implementation-plan-details-page.component';
import { ImplementationPlanPageComponent } from '../pages/implementation-plan-page/implementation-plan-page.component';
import { ImplementationPlanSharingPageComponent } from '../pages/implementation-plan-sharing-page/implementation-plan-sharing-page.component';
import { ImplementationStatusReportsPageComponent } from '../pages/implementation-status-reports-page/implementation-status-reports-page.component';
import { CreatePdSessionPageComponent } from '../pages/learning/create-pd-session/create-pd-session.component';
import { MyResourcesPageComponent } from '../pages/my-resources-page/my-resources-page.component';
import { ObservationFormPageComponent } from '../pages/observation/observation-form-page/observation-form-page.component';
import { ObservationInfoPageComponent } from '../pages/observation/observation-info-page/observation-info-page.component';
import { ObservationSummaryPageComponent } from '../pages/observation/observation-summary-page/observation-summary-page.component';
import { PermissionDeniedPageComponent } from '../pages/permission-denied/permission-denied.component';
import { PlansPageComponent } from '../pages/plans-page/plans-page.component';
import { PortfolioBadgesPageComponent } from '../pages/portfolio-badges-page/portfolio-badges-page.component';
import { PortfolioViewPageComponent } from '../pages/portfolio-view-page/portfolio-view-page.component';
import { ReportingBadgeProgressComponent } from '../pages/reporting-badge-progress/reporting-badge-progress.component';
import { ReportingDownloadComponent } from '../pages/reporting-download-page/reporting-download.component';
import { ReportingEvidenceComponent } from '../pages/reporting-evidence/reporting-evidence.component';
import { ReportingObservationsComponent } from '../pages/reporting-observations/reporting-observations.component';
import { ReportingPageComponent } from '../pages/reporting-page/reporting-page.component';
import { ReportingSessionDetailsComponent } from '../pages/reporting-session-details/reporting-session-details.component';
import { ReportingSupportInsightsComponent } from '../pages/reporting-support-insights/reporting-support-insights.component';
import { ResourceViewPageComponent } from '../pages/resource-view-page/resource-view-page.component';
import { ResourcesOnlineCoursesComponent } from '../pages/resources-online-courses/resources-online-courses.component';
import { ResourcesSearchComponent } from '../pages/resources-search/resources-search.component';
import { ResourcesVideoLibraryComponent } from '../pages/resources-video-library/resources-video-library.component';
import { RostersPageComponent } from '../pages/rosters-page/rosters-page.component';
import { SharedResourcesPageComponent } from '../pages/shared-resources-page/shared-resources-page.component';
import { SingleCelebrationPageComponent } from '../pages/single-celebration-page/single-celebration-page.component';
import { SmartCoachInfoPageComponent } from '../pages/smart-coach/smart-coach-info-page/smart-coach-info-page.component';
import { SmartCoachIntroPageComponent } from '../pages/smart-coach/smart-coach-intro-page/smart-coach-intro-page.component';
import { SmartCoachLogPageComponent } from '../pages/smart-coach/smart-coach-log-page/smart-coach-log-page.component';
import { TopicsCompetenciesPageComponent } from '../pages/topics-page/topics-competencies-page/topics-competencies-page.component';
import { TopicsPageComponent } from '../pages/topics-page/topics-page.component';
import { TranscriptPageComponent } from '../pages/transcript-page/transcript-page.component';
import { UserSettingsPageComponent } from '../pages/user-settings/user-settings.component';
import { FolderComponent } from '../shared/components/resources/folder/folder.component';
import { SharedFolderComponent } from '../shared/components/resources/shared-folder/shared-folder.component';
import { FEATURE_FLAGS } from '../shared/services/unleash/unleash.helpers';

export const privateRoutes: PrivateRoute[] = [
  {
    ...AUTH_GUARDED_ROUTE,
    path: '',
    component: CoachDashboardComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'permission-denied',
    title: 'Permission Denied',
    component: PermissionDeniedPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: `dashboard/${UserType.COACH}`,
    title: 'Coach Dashboard',
    component: CoachDashboardComponent,
    data: {
      roles: [UserRole.COACH],
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: `dashboard/${UserType.LEADER}`,
    title: 'Leader Dashboard',
    component: LeaderDashboardComponent,
    data: {
      roles: [
        UserRole.DISTRICT_ADMIN,
        UserRole.SCHOOL_ADMIN,
        UserRole.OPERATOR,
      ],
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: `dashboard/${UserType.LEARNER}`,
    title: 'Learner Dashboard',
    component: LearnerDashboardComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'dashboard/coachee-list',
    title: 'Coachee List',
    component: CoacheeListPageComponent,
    data: {
      roles: [UserRole.COACH],
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'dashboard/coach-list',
    title: 'Coach List',
    component: CoachListPageComponent,
    data: {
      roles: [UserRole.COACH],
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'dashboard/coachee-evidence',
    title: 'Coachee Evidence',
    component: CoacheeEvidencePageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_COACH,
    path: 'dashboard/coach-feedback',
    title: 'Coach Feedback',
    component: CoachFeedbackPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'portfolio/:userId',
    title: "User's Portfolio",
    component: PortfolioViewPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'portfolio/:userId/badges',
    title: 'Portfolio Badges',
    component: PortfolioBadgesPageComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'session-routing/:logId',
    title: 'All Sessions redirect',
    component: DashboardComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'coaching/log/:logId',
    title: 'Coaching Session redirect',
    component: DashboardComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'coaching/log/:logId/info',
    title: 'Coaching Session Info',
    component: CoachingLogInfoPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'coaching/log/:logId/competencies',
    title: 'Coaching Session Competencies',
    component: CoachingLogPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'coaching/log/:logId/growth',
    title: 'Coaching Session Coaching',
    component: CoachingLogPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'coaching/log/:logId/summary',
    title: 'Coaching Session Summary',
    component: CoachingLogPageComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'observation/log/:logId',
    title: 'Observation Form redirect',
    component: DashboardComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'observation/log/:logId/info',
    title: 'Observation Session Info',
    component: ObservationInfoPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'observation/log/:logId/form',
    title: 'Observation Session Form',
    component: ObservationFormPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'observation/log/:logId/summary',
    title: 'Observation Session Summary',
    component: ObservationSummaryPageComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'general/log/:logId',
    title: 'Observation Form redirect',
    component: DashboardComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'general/log/:logId/info',
    title: 'General Session Info',
    component: GeneralLogInfoPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'general/log/:logId/notes',
    title: 'General Session Form',
    component: GeneralLogPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'smart-coach/log/:logId/info',
    title: 'MySmart Coach Session Info',
    component: SmartCoachInfoPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'smart-coach/log/:logId/competencies',
    title: 'MySmart Coach Session Competencies',
    component: SmartCoachLogPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'smart-coach/log/:logId/growth',
    title: 'MySmart Coach Session Coaching',
    component: SmartCoachLogPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'smart-coach/log/:logId/summary',
    title: 'MySmart Coach Session Summary',
    component: SmartCoachLogPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'smart-coach/intro-session',
    title: 'MySmart Coach Intro Session',
    component: SmartCoachIntroPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources',
    title: 'Resources',
    component: ResourcesSearchComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/my',
    title: 'My Resources',
    component: MyResourcesPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/my/folder/:folderId',
    title: 'My Resources Folder',
    component: FolderComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/my/folder/created-by-you',
    title: 'My Resources Folder',
    component: FolderComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/shared/:id',
    component: SharedResourcesPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/shared/:id/folder/:folderId',
    component: SharedFolderComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/video-library',
    title: 'Video Library',
    component: ResourcesVideoLibraryComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/courses',
    title: 'Online Courses',
    component: ResourcesOnlineCoursesComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources/:resourceId',
    title: 'Resource',
    component: ResourceViewPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'settings/user',
    title: 'User Settings',
    component: UserSettingsPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'celebrations',
    title: 'All Celebrations',
    component: AllCelebrationsPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'celebrations/:celebrationId',
    title: 'Celebration',
    component: SingleCelebrationPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode',
    title: 'Edit District',
    component: EditDistrictComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/new-district',
    title: 'Add a District',
    component: AddDistrictComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE_E2L_EMPLOYEE_COACHES,
    path: 'coachee-evidence',
    title: 'Coachee Evidence',
    component: CoacheeEvidencePageComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE_E2L_EMPLOYEE,
    path: 'settings/forms/:formId',
    title: 'Form templates',
    component: EditFormPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/rosters',
    title: 'Rosters',
    component: RostersPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/topics',
    title: 'Topics',
    component: TopicsPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/topics/:topicId',
    title: 'Topics > Competencies',
    component: TopicsCompetenciesPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/competencies',
    title: 'Competencies',
    component: CoachingCompetenciesPageComponent,
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/forms',
    title: 'Forms',
    component: FormsListPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/forms/create',
    title: 'New Form',
    component: CreateFormPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/forms/:formId',
    title: 'Edit Form',
    component: EditFormPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/cohorts',
    title: 'Cohorts',
    component: CohortsPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_OPERATOR,
    path: 'settings/districts/:districtCode/configuration',
    title: 'Configuration',
    component: ConfigurationPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'plans',
    title: 'Plans',
    component: PlansPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'plans/implementation-plan/:planId/dashboard',
    title: 'Implementation Plan',
    component: ImplementationPlanPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'plans/implementation-plan/:planId/details',
    title: 'Implementation Plan Details',
    component: ImplementationPlanDetailsPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'plans/implementation-plan/:planId/sharing',
    title: 'Implementation Plan Sharing',
    component: ImplementationPlanSharingPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'plans/implementation-plan/:planId/status-reports',
    title: 'Implementation Plan Status Reports',
    component: ImplementationStatusReportsPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_COACH,
    path: 'reporting',
    title: 'Reporting',
    component: ReportingPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_COACH,
    path: 'reporting/support-insights',
    title: 'Support Insights',
    component: ReportingSupportInsightsComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_COACH,
    path: 'reporting/evidence',
    title: 'Evidence',
    component: ReportingEvidenceComponent,
    data: {
      ...AUTH_GUARDED_ROUTE_COACH.data,
      featureFlags: [FEATURE_FLAGS.evidenceReporting],
    },
  },
  {
    ...AUTH_GUARDED_ROUTE_COACH,
    path: 'reporting/badge-progress',
    title: 'Badge Progress',
    component: ReportingBadgeProgressComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_COACH,
    path: 'reporting/observations',
    title: 'Observations',
    component: ReportingObservationsComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_COACH,
    path: 'reporting/session-details',
    title: 'Session Details',
    component: ReportingSessionDetailsComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE_COACH,
    path: 'reporting/download-csv/:jobId',
    title: 'Download CSV',
    component: ReportingDownloadComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'training/transcript',
    title: "User's Transcript",
    component: TranscriptPageComponent,
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'training/transcript/:userId',
    title: "User's Transcript",
    component: TranscriptPageComponent,
  },
  {
    path: 'training/create-session',
    title: 'Create Learning Session',
    component: CreatePdSessionPageComponent,
    data: {
      featureFlags: [FEATURE_FLAGS.trainingSessions],
    },
  },
];
