<div class="container">
  <div class="d-flex justify-content-between mb-3">
    <h1>User Settings</h1>
  </div>
  <div class="card border-0 p-2 table-container">
    <div class="card-body mb-5">
      <ng-container *ngIf="!isLoading && user; else loadingTemplate">
        <div class="profile-picture-container">
          <div class="position-relative">
            <div class="profile-picture-upload">
              <app-file-upload
                #photoUploadComponent
                tooltipMessage="{{
                  user.profile.photoUrl
                    ? 'Replace profile picture'
                    : 'Upload profile picture'
                }}"
                (outputFiles)="updateProfilePicture($event)"
                [style]="uploadStyle"
                [uploadType]="uploadType"
                size="lg"
                customMessageAddOn="profile picture" />
            </div>
            <button
              *ngIf="user.profile.photoUrl"
              (click)="deleteProfilePicture()"
              aria-label="delete profile picture button"
              class="btn btn-delete profile-picture-delete"
              type="button"
              [autoClose]="'outside'"
              ngbTooltip="Delete profile picture">
              <i class="bi bi-trash"></i>
            </button>
            <app-avatar
              [avatarSize]="'xx-large'"
              [profile]="user.profile"></app-avatar>
          </div>
          <h2 class="mt-2 mb-1">{{ user.fullName }}</h2>
          <p *ngIf="user.district">{{ user.district.title }}</p>
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div
              class="container mb-5"
              style="max-width: 800px; margin: 0 auto">
              <div class="row mb-2">
                <div class="col-12 col-md-6">
                  <label for="first-name" class="form-label">First Name</label>
                  <div class="input-group">
                    <input
                      formControlName="first_name"
                      type="text"
                      class="form-control"
                      id="first-name"
                      aria-label="First Name"
                      [(ngModel)]="user.profile.first_name"
                      (ngModelChange)="updateTextValue($event, 'First Name')"
                      [ngClass]="{
                        'is-invalid': formSubmitted && f['first_name'].errors
                      }" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <label for="last-name" class="form-label">Last Name</label>
                  <div class="input-group">
                    <input
                      formControlName="last_name"
                      type="text"
                      class="form-control"
                      id="last-name"
                      aria-label="Last Name"
                      [(ngModel)]="user.profile.last_name"
                      (ngModelChange)="updateTextValue($event, 'Last Name')"
                      [ngClass]="{
                        'is-invalid': formSubmitted && f['last_name'].errors
                      }" />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <label for="email-address" class="form-label"
                    >Email Address</label
                  >
                  <div class="input-group">
                    <input
                      [disabled]="true"
                      type="text"
                      class="form-control"
                      id="email-address"
                      aria-label="Email Address"
                      [(ngModel)]="user.email"
                      [ngModelOptions]="{ standalone: true }" />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <label for="title" class="form-label">Title</label>
                  <div class="input-group">
                    <input
                      formControlName="title"
                      type="text"
                      class="form-control"
                      id="title"
                      aria-label="Title"
                      [(ngModel)]="user.profile.title"
                      (ngModelChange)="updateTextValue($event, 'Title')"
                      [ngClass]="{
                        'is-invalid': formSubmitted && f['title'].errors
                      }" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label">School</label>
                    <p class="mb-0">{{ user.school?.title }}</p>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label">Coach</label>
                    <p class="mb-0">
                      {{
                        user.coach?.profile?.first_name +
                          " " +
                          user.coach?.profile?.last_name
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <label
                    [attr.for]="'grade-levels-served'"
                    id="grade-levels-served-label"
                    class="form-label"
                    >Grade Levels Served</label
                  >
                  <ng-select
                    #gradeLevelsServedSelect
                    formControlName="grade_levels_served"
                    id="grade-levels-served"
                    [clearable]="false"
                    [labelForId]="'grade-levels-served'"
                    autocomplete="off"
                    (change)="
                      updateMultiSelectValues($event, 'grade_levels_served')
                    "
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && f['grade_levels_served'].errors
                    }">
                    <ng-option value=" " disabled selected
                      >Select an option</ng-option
                    >
                    <ng-container *ngFor="let grade of gradeOptions">
                      <ng-option [value]="grade.id">{{
                        grade.title
                      }}</ng-option>
                    </ng-container>
                  </ng-select>
                  <div class="edit-user-badge">
                    <ng-container *ngFor="let grade of gradesPayload">
                      <span
                        class="badge badge-item mt-2"
                        (click)="removeMultiSelectValue('grade', grade)"
                        >{{ grade.title }}<i class="bi bi-x mx-2"></i
                      ></span>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col">
                  <label [attr.for]="'content-areas'" class="form-label"
                    >Content Areas</label
                  >
                  <ng-select
                    #contentAreasSelect
                    formControlName="content_areas"
                    id="content-areas"
                    [labelForId]="'content-areas'"
                    autocomplete="off"
                    [clearable]="false"
                    (change)="updateMultiSelectValues($event, 'content_areas')"
                    [ngClass]="{
                      'is-invalid': formSubmitted && f['content_areas'].errors
                    }">
                    <ng-option value=" " disabled selected
                      >Select an option</ng-option
                    >
                    <ng-container *ngFor="let area of contentAreaOptions">
                      <ng-option [value]="area.id">{{ area.title }}</ng-option>
                    </ng-container>
                  </ng-select>
                  <div class="edit-user-badge">
                    <ng-container *ngFor="let area of contentAreasPayload">
                      <span
                        class="badge badge-item mt-2"
                        (click)="removeMultiSelectValue('contentArea', area)"
                        >{{ area.title }}<i class="bi bi-x mx-2"></i
                      ></span>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div
                *ngIf="errorMessage"
                class="alert alert-danger text-start mt-3 mb-0 d-flex justify-content-between"
                role="alert">
                {{ errorMessage }}
                <button
                  type="button"
                  class="btn-close"
                  (click)="errorMessage = ''"
                  data-bs-dismiss="alert"
                  aria-label="Close"></button>
              </div>
              <div class="row mt-3">
                <div class="col text-end">
                  <button
                    [disabled]="submitLoading"
                    class="btn btn-primary"
                    (click)="updateUser()">
                    <ng-container *ngIf="submitLoading">
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </ng-container>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ng-container>
      </ng-container>
      <ng-template #loadingTemplate>
        <div
          class="w-100 position-relative"
          style="background-color: #fff; height: 500px">
          <app-loader></app-loader>
        </div>
      </ng-template>
    </div>
  </div>
</div>
