<ng-container *ngIf="true">
  <div class="pb-4">
    <div class="session-item-wrapper d-flex flex-column flex-lg-row w-100">
      <div
        class="session-image"
        [ngStyle]="{
          'background-image': backgroundImage
        }">
        <div
          class="date-box d-flex flex-column justify-content-center align-items-center fs-17">
          <div class="month fw-800 fs-23">
            {{ session.start_datetime * 1000 | date : "MMM" | uppercase }}
          </div>
          <div class="day fw-800 fs-28">
            {{ session.start_datetime * 1000 | date : "dd" }}
          </div>
        </div>
      </div>
      <div class="info px-4 py-3">
        <div class="item-header d-flex flex-row justify-content-between">
          <div class="me-3">
            <div class="title fs-26 fw-800 mb-2">
              <ng-container *ngIf="context === 'normal'">
                <a href="/training/session/{{ session.id }}">{{
                  session.title
                }}</a>
              </ng-container>
              <ng-container *ngIf="context === 'copy'">
                {{ session.title }}
              </ng-container>
            </div>
            <div class="fs-17">
              {{ session.start_datetime | formatDateOrdinal }},
              <span style="text-transform: lowercase">
                {{ session.start_datetime * 1000 | date : "hh:mma" }}
                <span *ngIf="session.end_datetime">
                  - {{ session.end_datetime * 1000 | date : "hh:mma" }}</span
                ></span
              >
            </div>
          </div>
          <div class="action-bar d-none d-lg-block">
            <ng-container *ngIf="!session.is_completed">
              <ng-container *ngIf="context === 'normal'">
                <button
                  *ngIf="!isRegistered"
                  type="button"
                  class="btn btn-primary btn-register float-end fw-600"
                  style="font-size: 20px"
                  (click)="register(session.id)">
                  Register
                </button>
                <button
                  *ngIf="isRegistered"
                  type="button"
                  class="btn btn-secondary btn-register float-end fw-600"
                  style="font-size: 20px"
                  (click)="unregister(session.id)">
                  Attending
                </button>
              </ng-container>
              <ng-container *ngIf="context === 'copy'">
                <button
                  type="button"
                  class="btn btn-primary btn-select float-end fw-600"
                  style="font-size: 20px"
                  (click)="handleSelect(session)">
                  Select Event
                </button>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <hr class="item-div" />
        <div
          class="item-details d-flex flex-column flex-lg-row justify-content-between">
          <div class="left me-3">
            <div
              class="d-flex flex-row justify-content-start align-items-start mb-2">
              <div class="label" style="width: 100px; min-width: 100px">
                <strong
                  >Facilitator<span
                    *ngIf="
                      session.facilitators && session.facilitators.length > 1
                    "
                    >s</span
                  ></strong
                >:
              </div>
              <div class="facil fs-14" style="margin-top: 2px">
                <ng-container
                  *ngIf="
                    session.facilitators && session.facilitators.length > 0
                  ">
                  <ng-container
                    *ngFor="
                      let facilitator of session.facilitators;
                      let last = last
                    ">
                    <app-users-name
                      [userId]="
                        facilitator.facilitator_user_id
                      "></app-users-name
                    ><span *ngIf="!last">, </span>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <div class="desc fs-14">
              {{ session.description }}
            </div>
          </div>
          <div class="right pt-1">
            <div class="item">
              <div class="label"><strong>Location:</strong></div>
              <ng-container *ngIf="session.is_virtual; else physical">
                Virtual Session
              </ng-container>
              <ng-template #physical>
                {{ session.location }}
              </ng-template>
            </div>
            <div class="item">
              <div class="label"><strong>Type:</strong></div>
              {{ session.type }}
            </div>
            <div class="item d-flex">
              <div class="label"><strong>Credits:</strong></div>
              <div class="credits-wrapper">
                <ng-container
                  *ngIf="session.credits && session.credits.length > 0">
                  <ng-container *ngFor="let credit of session.credits">
                    <div class="credit-item">
                      <div class="amt">{{ credit.credit_amount }}</div>
                      <div class="type">{{ credit.credit_type }}</div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bottom-action-bar d-flex justify-content-center d-lg-none pt-3 pb-2">
          <ng-container *ngIf="context === 'normal'">
            <button
              *ngIf="!isRegistered"
              type="button"
              class="btn btn-primary btn-register fw-600"
              style="font-size: 20px"
              (click)="register(session.id)">
              Register
            </button>
            <button
              *ngIf="isRegistered"
              type="button"
              class="btn btn-secondary btn-register fw-600"
              style="font-size: 20px"
              (click)="unregister(session.id)">
              Attending
            </button>
          </ng-container>
          <ng-container *ngIf="context === 'copy'">
            <button
              type="button"
              class="btn btn-primary btn-select fw-600"
              style="font-size: 20px"
              (click)="handleSelect(session)">
              Select Event
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
