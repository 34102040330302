<div class="container">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <div class="col-9">
      <a routerLink="/plans" class="text-decoration-none text-body-secondary"
        >&lt; Back to Plans</a
      >
      <span class="mx-3">|</span>
      <span class="fw-800">Implementation Plan</span>
    </div>
    <div class="col-3 col-md-auto">
      <app-action-buttons />
    </div>
  </div>

  <ng-container *ngIf="planDetails">
    <ng-container *ngIf="!planDetails.forbidden; else deniedMessage">
      <div class="row mt-2 mb-4">
        <div *ngIf="planDetails?.title; else noTitle" class="col text-center">
          <div class="d-flex justify-content-center align-items-center">
            <h1 class="fw-800 plan-title">
              {{ planDetails.title }}
            </h1>
          </div>
          <div>
            {{ planDetails.start_date }} -
            {{ planDetails.end_date }}
          </div>
        </div>
        <ng-template #noTitle>
          <div style="height: 7.5rem"></div>
        </ng-template>
      </div>

      <div class="card bg-none border-0">
        <app-plans-subnav [planId]="planDetails.id" />
        <div class="card-body bg-white dashboard-card">
          <ng-container *ngIf="planDetails.phase_displayname; else loading">
            <div class="w-100 d-flex justify-content-between mb-3">
              <div class="w-25">
                <app-year-select
                  *ngIf="planSchoolYearList.length > 1"
                  [setYearList]="planSchoolYearList"
                  [clearAfterSelection]="false"
                  [clearable]="true"
                  placeholder="School Year"
                  (selectedYear)="selectedSchoolYear = $event" />
              </div>
              <button
                *ngIf="planEditor && planDetails.phases.length !== 0"
                type="button"
                class="btn btn-primary btn-create-phase"
                (click)="openCreatePhaseModal()">
                <i class="bi bi-plus"></i> Create a
                {{ planDetails.phase_displayname }}
              </button>
            </div>
            <ng-container *ngIf="planDetails.phases.length > 0; else noPhases">
              <div
                cdkDropList
                [cdkDropListData]="planDetails.phases"
                class="drop-phases"
                (cdkDropListDropped)="dropPhase(planDetails.phases, $event)">
                <div
                  *ngFor="let phase of planDetails.phases | sort : 'sort_order'"
                  cdkDragBoundary=".drop-phases"
                  cdkDragLockAxis="y"
                  cdkDrag>
                  <div ngbAccordion *ngIf="phase" class="phase mb-4">
                    <div
                      ngbAccordionItem
                      #phaseAcc="ngbAccordionItem"
                      [collapsed]="!isPhaseExpanded(phase.id)">
                      <div
                        ngbAccordionHeader
                        class="d-flex flex-row phase-header">
                        <div class="phase-content w-100">
                          <div class="d-flex justify-content-between">
                            <div class="phase-title mb-3">
                              <div
                                class="d-flex align-items-center"
                                ngbAccordionToggle
                                (click)="togglePhase(phase.id)"
                                class="phase-toggle">
                                <i
                                  [class.bi-caret-down-fill]="
                                    phaseAcc.collapsed
                                  "
                                  [class.bi-caret-up-fill]="
                                    !phaseAcc.collapsed
                                  "></i>
                                <span class="fw-bold fs-4 ms-2">{{
                                  phase.title
                                }}</span>
                              </div>
                              <div class="phase-status">
                                <app-plans-status-select
                                  [viewOnly]="!planEditor && !phase.canEdit"
                                  [status]="phase.status"
                                  (selectedStatus)="
                                    onPhaseStatusChange(phase.id, $event)
                                  "></app-plans-status-select>
                              </div>
                            </div>
                            <i
                              *ngIf="planEditor || phase.canEdit"
                              (click)="openEditPhaseModal(phase)"
                              class="bi bi-pencil color-tertiary"
                              style="cursor: pointer"
                              ngbTooltip="Edit {{
                                planDetails.phase_displayname
                              }}"></i>
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-12 px-4 phase-description">
                              {{ phase.description }}
                            </div>
                            <div class="col-md-6 col-12">
                              <div
                                class="d-flex align-items-start align-items-lg-center phase-assignees flex-column flex-lg-row">
                                <p class="mb-0 fw-bold mb-2">Assignee(s):</p>
                                <div
                                  *ngIf="phase.assignments.length > 0"
                                  class="d-flex flex-column flex-xl-row flex-wrap w-100">
                                  <ng-container
                                    *ngFor="
                                      let assignment of phase.assignments
                                    ">
                                    <div
                                      class="ps-lg-4 mb-2 col-12"
                                      [ngClass]="
                                        phase.assignments.length > 1
                                          ? 'col-xl-6'
                                          : ''
                                      ">
                                      <div
                                        class="d-flex flex-nowrap align-items-center">
                                        <app-avatar
                                          [profile]="assignment.user.profile"
                                          [avatarSize]="'small'">
                                        </app-avatar>
                                        <div class="ms-2">
                                          {{
                                            assignment.user.profile.first_name
                                          }}
                                          {{
                                            assignment.user.profile.last_name
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </div>
                          <app-plans-yearly-progress [phase]="phase" />
                        </div>
                        <div
                          *ngIf="planDetails.phases.length > 1"
                          class="drag-column"
                          style="flex: 1">
                          <div
                            class="drag-handle phase-handle d-flex justify-content-center align-items-center"
                            cdkDragHandle>
                            <i class="bi bi-grip-vertical"></i>
                          </div>
                        </div>
                      </div>
                      <div ngbAccordionCollapse>
                        <div class="phase-scroll">
                          <div ngbAccordionBody class="phase-body">
                            <div class="deliverable-table-header">
                              <div class="title-column">
                                {{ planDetails.deliverable_displayname }}
                              </div>
                              <div class="status-column">Status</div>
                              <div class="progress-column">Progress</div>
                              <div class="department-column">Department</div>
                              <div class="assignee-column">Assignee(s)</div>
                              <div class="duedate-column">Due Date</div>
                              <div class="icon-column"></div>
                              <div
                                class="drag-column"
                                *ngIf="phase.deliverables.length > 1"></div>
                            </div>

                            <div
                              cdkDropList
                              [cdkDropListData]="phase.deliverables"
                              class="drop-deliverables"
                              (cdkDropListDropped)="
                                dropDeliverable(phase.deliverables, $event)
                              ">
                              <div
                                *ngFor="
                                  let deliverable of phase.deliverables
                                    | sort : 'sort_order'
                                    | checkDeliverablesWithinYear
                                      : selectedSchoolYear
                                "
                                cdkDragBoundary=".drop-deliverables"
                                cdkDragLockAxis="y"
                                cdkDrag>
                                <div *ngIf="deliverable" ngbAccordion>
                                  <div
                                    ngbAccordionItem
                                    [collapsed]="
                                      !isDeliverableExpanded(deliverable.id)
                                    "
                                    #deliverableAcc="ngbAccordionItem"
                                    class="deliverable-accordion-item">
                                    <div
                                      ngbAccordionHeader
                                      class="d-flex w-100 align-items-stretch">
                                      <div class="deliverable-item-row">
                                        <div class="title-column pe-3">
                                          <div
                                            class="d-flex align-items-center mb-1">
                                            <i
                                              ngbAccordionToggle
                                              (click)="
                                                toggleDeliverable(
                                                  deliverable.id
                                                )
                                              "
                                              class="deliverable-toggle"
                                              [class.bi-caret-down-fill]="
                                                deliverableAcc.collapsed
                                              "
                                              [class.bi-caret-up-fill]="
                                                !deliverableAcc.collapsed
                                              "></i>
                                            <span
                                              ngbAccordionToggle
                                              (click)="
                                                toggleDeliverable(
                                                  deliverable.id
                                                )
                                              "
                                              class="fw-bold ms-2 me-1 text-start"
                                              style="cursor: pointer">
                                              {{ deliverable.title }}
                                            </span>
                                            <i
                                              *ngIf="deliverable.has_comments"
                                              class="bi bi-chat-text color-tertiary ms-2"
                                              ngbTooltip="Has comments"></i>
                                            <i
                                              *ngIf="
                                                deliverable.has_attachments
                                              "
                                              class="bi bi-paperclip color-tertiary ms-1"
                                              ngbTooltip="Has attachments"></i>
                                            <i
                                              *ngIf="deliverable.has_notes"
                                              class="bi bi-journal color-tertiary ms-1"
                                              ngbTooltip="Has notes"></i>
                                          </div>
                                          <div
                                            class="fs-14 fw-light text-start deliverable-description">
                                            {{ deliverable.description }}
                                            <div class="mt-3 mobile-status">
                                              <app-plans-status-select
                                                [viewOnly]="
                                                  !planEditor &&
                                                  !deliverable.canEdit
                                                "
                                                [status]="deliverable.status"
                                                (selectedStatus)="
                                                  onDeliverableStatusChange(
                                                    deliverable.id,
                                                    $event
                                                  )
                                                "></app-plans-status-select>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="status-column">
                                          <app-plans-status-select
                                            [viewOnly]="
                                              !planEditor &&
                                              !deliverable.canEdit
                                            "
                                            [status]="deliverable.status"
                                            (selectedStatus)="
                                              onDeliverableStatusChange(
                                                deliverable.id,
                                                $event
                                              )
                                            "></app-plans-status-select>
                                        </div>
                                        <div class="progress-column">
                                          {{
                                            formatProgress(
                                              deliverable.progress
                                            )
                                          }}%
                                        </div>
                                        <div class="department-column">
                                          {{ deliverable.department }}
                                        </div>
                                        <div class="assignee-column px-4">
                                          <ng-container
                                            *ngFor="
                                              let assignment of deliverable.assignments
                                            ">
                                            <div
                                              class="d-flex flex-nowrap align-items-center my-2">
                                              <app-avatar
                                                [profile]="
                                                  assignment.user.profile
                                                "
                                                [avatarSize]="'small'">
                                              </app-avatar>
                                              <div class="ms-2">
                                                {{
                                                  assignment.user.profile
                                                    .first_name
                                                }}
                                                {{
                                                  assignment.user.profile
                                                    .last_name
                                                }}
                                              </div>
                                            </div>
                                          </ng-container>
                                        </div>
                                        <div class="duedate-column">
                                          {{ deliverable.due_date | date }}
                                        </div>
                                        <div class="icon-column">
                                          <button
                                            class="btn-reset p-0"
                                            (click)="
                                              openEditDeliverableModal(
                                                deliverable.id,
                                                phase.id
                                              )
                                            "
                                            ngbTooltip="Edit {{
                                              planDetails.deliverable_displayname
                                            }}"
                                            [autoClose]="'true'">
                                            <i
                                              *ngIf="
                                                planEditor ||
                                                deliverable.canEdit
                                              "
                                              class="bi bi-fullscreen color-tertiary"></i>
                                          </button>
                                        </div>
                                      </div>

                                      <div
                                        class="drag-column"
                                        *ngIf="phase.deliverables.length > 1">
                                        <div
                                          class="drag-handle del-handle d-flex justify-content-center align-items-center">
                                          <i class="bi bi-grip-vertical"></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div ngbAccordionCollapse>
                                      <div ngbAccordionBody>
                                        <div
                                          class="action-item-header fs-12 fw-600 text-uppercase">
                                          <div class="triangle-topright"></div>
                                          <div class="banner">
                                            {{
                                              planDetails.actionitem_displayname
                                            }}s
                                          </div>
                                        </div>

                                        <div class="ms-5">
                                          <div class="action-item-table-header">
                                            <div class="title-column">
                                              <span class="ms-5">Title</span>
                                            </div>
                                            <div class="status-column">
                                              Status
                                            </div>
                                            <div class="assignee-column">
                                              Assignee(s)
                                            </div>
                                            <div class="duedate-column">
                                              Due Date
                                            </div>
                                            <div class="icon-column"></div>
                                            <div
                                              *ngIf="
                                                deliverable.actionItems.length >
                                                1
                                              "
                                              class="drag-column"></div>
                                          </div>
                                        </div>
                                        <div class="ms-5">
                                          <div
                                            cdkDropList
                                            [cdkDropListData]="
                                              deliverable.actionItems
                                            "
                                            class="drop-action-items"
                                            (cdkDropListDropped)="
                                              dropActionItem(
                                                deliverable.actionItems,
                                                $event
                                              )
                                            ">
                                            <div
                                              *ngFor="
                                                let actionItem of deliverable.actionItems
                                                  | sort : 'sort_order'
                                              "
                                              cdkDragBoundary=".drop-action-items"
                                              cdkDragLockAxis="y"
                                              cdkDrag>
                                              <div
                                                *ngIf="
                                                  actionItem
                                                    | checkActionItemWithinYear
                                                      : selectedSchoolYear
                                                "
                                                class="row-wrapper">
                                                <div class="action-item-row">
                                                  <div class="title-column">
                                                    <div
                                                      class="d-flex align-items-center mb-1">
                                                      <span
                                                        class="fw-bold ms-2 me-1 text-start">
                                                        {{ actionItem.title }}
                                                      </span>
                                                      <i
                                                        *ngIf="
                                                          actionItem.has_comments
                                                        "
                                                        class="bi bi-chat-text color-tertiary ms-2"
                                                        ngbTooltip="Has comments"></i>
                                                      <i
                                                        *ngIf="
                                                          actionItem.has_attachments
                                                        "
                                                        class="bi bi-paperclip color-tertiary ms-1"
                                                        ngbTooltip="Has attachments"></i>
                                                      <i
                                                        *ngIf="
                                                          actionItem.has_notes
                                                        "
                                                        class="bi bi-journal color-tertiary ms-1"
                                                        ngbTooltip="Has notes"></i>
                                                    </div>
                                                    <div
                                                      class="ps-2 pe-3 fs-14 fw-light text-start">
                                                      {{
                                                        actionItem.description
                                                      }}
                                                    </div>
                                                  </div>
                                                  <div class="status-column">
                                                    <app-plans-status-select
                                                      [viewOnly]="
                                                        !planEditor &&
                                                        !actionItem.canEdit
                                                      "
                                                      [status]="
                                                        actionItem.status
                                                      "
                                                      (selectedStatus)="
                                                        onActionItemStatusChange(
                                                          actionItem.id,
                                                          $event
                                                        )
                                                      "></app-plans-status-select>
                                                  </div>
                                                  <div
                                                    class="assignee-column px-4">
                                                    <ng-container
                                                      *ngFor="
                                                        let assignment of actionItem.assignments
                                                      ">
                                                      <div
                                                        class="d-flex flex-nowrap align-items-center my-2">
                                                        <app-avatar
                                                          [profile]="
                                                            assignment.user
                                                              .profile
                                                          "
                                                          [avatarSize]="
                                                            'small'
                                                          ">
                                                        </app-avatar>
                                                        <div class="ms-2">
                                                          {{
                                                            assignment.user
                                                              .profile
                                                              .first_name
                                                          }}
                                                          {{
                                                            assignment.user
                                                              .profile.last_name
                                                          }}
                                                        </div>
                                                      </div>
                                                    </ng-container>
                                                  </div>
                                                  <div class="duedate-column">
                                                    {{
                                                      actionItem.due_date | date
                                                    }}
                                                  </div>
                                                  <div
                                                    class="icon-column text-center">
                                                    <button
                                                      class="btn-reset p-0 mt-2"
                                                      (click)="
                                                        openEditActionItemModal(
                                                          actionItem.id,
                                                          deliverable.id,
                                                          phase.id
                                                        )
                                                      ">
                                                      <i
                                                        *ngIf="
                                                          planEditor ||
                                                          actionItem.canEdit
                                                        "
                                                        class="bi bi-fullscreen color-tertiary"
                                                        ngbTooltip="Edit {{
                                                          planDetails.actionitem_displayname
                                                        }}"
                                                        [autoClose]="
                                                          'true'
                                                        "></i>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div
                                                  *ngIf="
                                                    deliverable.actionItems
                                                      .length > 1
                                                  "
                                                  class="drag-column">
                                                  <div
                                                    class="drag-handle action-handle d-flex justify-content-center align-items-center">
                                                    <i
                                                      class="bi bi-grip-vertical"></i>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <!-- / *ngFor deliver-->
                                          </div>
                                          <!-- /cdkDropList deliverable.actionItems -->
                                        </div>

                                        <div class="w-100 text-center mt-3">
                                          {{
                                            checkActionItemHidden(deliverable)
                                          }}
                                        </div>

                                        <!-- CREATE ACTION ITEM ROW -->
                                        <div
                                          *ngIf="
                                            planEditor || deliverable.canEdit
                                          "
                                          class="add-action-item-row"
                                          [class.empty]="
                                            deliverable.actionItems.length < 1
                                          ">
                                          <span
                                            *ngIf="
                                              deliverable.actionItems.length < 1
                                            "
                                            class="fs-16 fw-800 me-3"
                                            >Create your first Action
                                            Item:</span
                                          >
                                          <button
                                            (click)="
                                              createActionItemModal.openModal()
                                            "
                                            class="btn btn-primary btn-create-action ms-1">
                                            <i class="bi bi-plus"></i> Add
                                            {{
                                              planDetails.actionitem_displayname
                                            }}
                                          </button>
                                        </div>
                                        <app-create-action-item-modal
                                          #createActionItemModal
                                          [displayName]="
                                            planDetails.actionitem_displayname
                                          "
                                          [districtId]="planDetails.district_id"
                                          [deliverable]="deliverable" />
                                      </div>
                                      <!-- / ngbAccordionBody -->
                                    </div>
                                    <!-- /ngbAccordionCollapse -->
                                  </div>
                                  <!-- /ngbAccordionItem  -->
                                </div>
                                <!-- / deliverable-->
                              </div>
                              <!-- /ngbAccordion -->
                              <!-- / *ngFor deliverables-->
                            </div>
                            <!-- /cdkDropList phase.deliverables -->

                            <div class="w-100 text-center mt-3">
                              {{ checkDeliverableHidden(phase) }}
                            </div>

                            <div
                              *ngIf="planEditor || phase.canEdit"
                              class="add-deliverable-row"
                              [class.empty]="phase.deliverables.length < 1">
                              <span
                                *ngIf="phase.deliverables.length < 1"
                                class="fs-16 fw-800 me-3"
                                >Create your first Deliverable:</span
                              >
                              <button
                                class="btn btn-primary btn-create-deliverable"
                                (click)="openCreateDeliverableModal(phase.id)">
                                <i class="bi bi-plus"></i> Add
                                {{ planDetails.deliverable_displayname }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noPhases
              ><div
                *ngIf="planDetails?.phase_displayname"
                class="d-flex flex-column align-items-center justify-content-center w-100"
                style="min-height: 400px">
                <h2 class="mb-4">
                  Create your first {{ planDetails.phase_displayname }}
                </h2>
                <button
                  *ngIf="planEditor"
                  type="button"
                  class="btn btn-primary btn-create-phase"
                  (click)="openCreatePhaseModal()">
                  <i class="bi bi-plus"></i> Create a
                  {{ planDetails.phase_displayname }}
                </button>
              </div>
            </ng-template>
            <app-create-edit-phase-modal
              #createEditPhaseModal
              [displayName]="planDetails.phase_displayname"
              [planId]="planDetails.id"
              [isE2L]="isE2L"
              [planDistrictId]="planDetails.district_id"
              [currentUser]="user"></app-create-edit-phase-modal>
            <app-create-deliverable-modal
              #createDeliverableParentModal
              [planDetails]="planDetails" />
            <app-edit-deliverable-modal
              #editDeliverableParentModal
              [districtId]="planDetails.district_id"
              [displayName]="planDetails.deliverable_displayname"
              [deliverable]="currentDeliverable"
              [phaseId]="currentPhaseId"
              [planStartDate]="planDetails.start_date"
              [planEndDate]="planDetails.end_date" />
            <app-edit-action-item-modal
              #editActionItemModal
              [districtId]="planDetails.district_id"
              [displayName]="planDetails.actionitem_displayname"
              [actionItem]="currentActionItem"
              [parentDeliverable]="currentDeliverable"
              [phaseId]="currentPhaseId" />
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #deniedMessage>
    <div class="mt-5">
      <app-permission-denied />
    </div>
  </ng-template>
  <ng-template #loading>
    <div
      class="w-100 position-relative mt-3"
      style="background-color: #fff; height: 600px">
      <app-loader></app-loader>
    </div>
  </ng-template>
</div>
