import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { UserState } from 'src/app/common/state/user/user.state';
import { userFromDTO } from 'src/app/common/state/user/user.utilities';
import { DomElement } from 'src/app/common/utilities/dom-element';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { UploadStyles } from '../../shared/components/file-management/file-upload/file-upload.component';
import { YearSelectComponent } from '../../shared/components/year-select/year-select.component';
import {
  SortableHeader,
  SortEvent,
} from '../../shared/directives/sortable-header.directive';
import { FileDTO } from '../../shared/dtos/file.dto';
import {
  AddPdTranscriptEntryPayload,
  GetPdTranscriptsPayload,
  PdCredit,
  PdSettings,
  PdTranscriptDTO,
} from '../../shared/dtos/pd.dto';
import { UploadType } from '../../shared/enums/upload-type.enum';
import { PdEventEvidenceFileService } from '../../shared/services/file/pd-event-evidence.file.service';
import { PdService } from '../../shared/services/pd/pd.service';
import { CURRENT_SCHOOL_YEAR } from '../../shared/services/school-year/school-year.utilities';

@Component({
  selector: 'app-transcript-page',
  templateUrl: './transcript-page.component.html',
  styleUrl: './transcript-page.component.scss',
})
export class TranscriptPageComponent implements OnInit, OnDestroy {
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  @ViewChild('yearSelect') yearSelect: YearSelectComponent;

  currentUser: User;

  isAdmin = false;

  isE2L = false;

  transcriptUser: User;

  currentFilters: GetPdTranscriptsPayload = {
    sort_by: '',
    sort_by_desc: 0,
    school_year: CURRENT_SCHOOL_YEAR.name,
    credit_type: '',
    source: '',
  };

  transcriptData: PdTranscriptDTO[];

  addEntryMode = false;

  addEntryPayload = {} as AddPdTranscriptEntryPayload;

  formSubmitted = false;

  uploadStyle = UploadStyles.EVIDENCE;

  uploadType = UploadType.PD_EVENT_EVIDENCE;

  attachment: FileDTO | null;

  settings: PdSettings;

  yearlyTotals: PdCredit[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    date: 'gray',
    title: 'gray',
    source: 'gray',
    credit_type: 'gray',
  };

  sortInfo = {
    column: '',
    direction: '',
  };

  loadingIcons = true;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private pdService: PdService,
    private pdEventEvidenceService: PdEventEvidenceFileService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.store.selectSnapshot(UserState.getUser) as User;
    if (this.currentUser) {
      this.isE2L = checkIfE2L(this.currentUser);
      this.isAdmin =
        this.currentUser.roles.includes(UserRole.DISTRICT_ADMIN) ||
        this.currentUser.roles.includes(UserRole.OPERATOR) ||
        this.currentUser.roles.includes(UserRole.E2L_EMPLOYEE);
    }
    this.getProfileData();
    document.body.classList.add('session_post');
  }

  getProfileData() {
    this.route.params.subscribe((url) => {
      if (url['userId']) {
        this.userService.fetchUserById(url['userId']).subscribe(
          (res) => {
            if (res) {
              if (this.isAdmin) {
                this.transcriptUser = userFromDTO(res);
              } else {
                this.transcriptUser = this.currentUser;
              }
              this.getTranscriptData();
            }
          },
          (error) => {
            if (error.status === 404) {
              this.router.navigate(['/training/transcript']);
            }
          }
        );
      } else {
        this.transcriptUser = this.currentUser;
        this.getTranscriptData();
      }
    });
  }

  getTranscriptData() {
    this.pdService
      .getTranscript(
        this.isAdmin ? this.transcriptUser.id : this.currentUser.id,
        this.currentFilters
      )
      .subscribe((res) => {
        if (res.items) {
          this.transcriptData = res.items;
          this.pdEventEvidenceService.setUserId(this.transcriptUser.id);
          this.getYearlyTotals();
          this.updateColumns();
          this.parseEvidenceLinks();
          this.getSettings();
        }
      });
  }

  handleUserSearchOutput(user: UserLiteDTO | null) {
    if (user) {
      this.router.navigate([`/training/transcript/${user.id}`]);
    } else {
      this.router.navigate(['/training/transcript']);
    }
  }

  handleFileOutput(file: FileDTO) {
    this.attachment = file;
    this.addEntryPayload.attachment = file.location;
  }

  getSettings() {
    this.pdService
      .getAdminSettings(this.transcriptUser.district?.id)
      .subscribe((res) => {
        if (res) {
          this.settings = res.item;
        }
      });
  }

  onCreditValueChange(value: number) {
    if (value) {
      if (/[^0-9]/.test(value.toString())) {
        setTimeout(() => {
          this.addEntryPayload.credit_value = null;
        });
      }
      // Ensure that only a maximum of 2 digits are allowed
      if (value?.toString().length > 2) {
        setTimeout(() => {
          this.addEntryPayload.credit_value = parseInt(
            value.toString().slice(0, 2)
          );
        });
      }
    }
  }

  addManualEntry() {
    this.formSubmitted = true;
    this.addEntryPayload.source = 'Manual Entry';
    if (
      this.addEntryPayload.date &&
      this.addEntryPayload.title &&
      this.addEntryPayload.credit_type &&
      this.addEntryPayload.credit_value
    ) {
      this.pdService
        .addTranscriptEntry(this.transcriptUser.id, this.addEntryPayload)
        .subscribe((res) => {
          if (res) {
            this.getTranscriptData();
            this.addEntryPayload = {} as AddPdTranscriptEntryPayload;
            this.formSubmitted = false;
            this.addEntryMode = false;
            this.attachment = null;
          }
        });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  viewFile(location: string) {
    window.open(`${EnvironmentService.apiUrl()}${location}`);
  }

  handleSelectedYear(schoolYear: SchoolYearDTO | null) {
    if (schoolYear) {
      this.currentFilters.school_year = schoolYear.name;
      this.getTranscriptData();
    }
  }

  clearManualEntry() {
    this.addEntryMode = !this.addEntryMode;
    this.addEntryPayload = {} as AddPdTranscriptEntryPayload;
    this.formSubmitted = false;
    this.attachment = null;
  }

  getYearlyTotals() {
    this.pdService
      .getTranscriptYearlyTotals(
        this.transcriptUser.id,
        this.currentFilters.school_year
      )
      .subscribe((res) => {
        if (res.items) {
          this.yearlyTotals = res.items;
        }
      });
  }

  updateColumns() {
    this.headers.forEach((header) => {
      if (header.sortable !== this.sortInfo.column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });
    if (this.sortInfo.direction === '' || this.sortInfo.column === '') {
      this.sortIconTemp[this.sortInfo.column] = 'gray';
    }
    this.loadingIcons = false;
  }

  onSort({ column, direction }: SortEvent) {
    if (direction === '') {
      this.currentFilters.sort_by = '';
      this.currentFilters.sort_by_desc = 0;
    } else {
      this.currentFilters.sort_by = column;
      if (direction === 'asc') {
        this.currentFilters.sort_by_desc = 0;
      } else if (direction === 'desc') {
        this.currentFilters.sort_by_desc = 1;
      }
    }
    this.sortInfo.column = column;
    this.sortInfo.direction = direction;
    this.loadingIcons = true;
    this.getTranscriptData();
  }

  parseEvidenceLinks() {
    if (this.transcriptData) {
      this.transcriptData.forEach((item, index) => {
        if (item.evidence_link) {
          this.transcriptData[index].evidence_link = `/uploads${
            item.evidence_link.split('uploads')[1]
          }`;
          this.transcriptData[index].evidence_title = this.transcriptData[
            index
          ].evidence_link
            .split('/')
            .at(-1)
            ?.split('.')[0];
        }
      });
    }
  }

  downloadTranscript() {
    this.pdService
      .downloadTranscript(this.transcriptUser.id)
      .subscribe((link) => {
        if (link) {
          const downloadLink = DomElement.create(
            `a[href="${link}"][download="${this.transcriptUser.id}-Transcript"][target="_blank"]`
          );
          downloadLink.click();
          downloadLink.remove();
        }
      });
  }

  deleteManualEntry(entryId: number) {
    this.pdService.deleteTranscriptEntry(entryId).subscribe((res) => {
      if (res) {
        this.getTranscriptData();
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  ngOnDestroy() {
    document.body.classList.remove('session_post');
  }
}
