<ng-container *ngIf="displayData && displayData.length">
  <table class="table">
    <thead>
      <tr>
        <th>Outliers</th>
        <th>Times Used</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of displayData" class="frequency-table-row">
        <td>
          <span class="circle" style="background: {{ data.color }};"></span>
          {{ data.name }}
        </td>
        <td>{{ data.value }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>
