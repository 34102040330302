import { districtDTOFromAPIResponse } from '../../dtos/translators/district.translator';
import { booleanFromBooleanNumber } from '../../utilities/enums/boolean-number.enum';
import { getRolesFromDTO } from './role/user-role';
import { getUserTypeFromDTO, UserType } from './type/user-type';
import { UserDTO } from './user.dto';
import { User } from './user.model';

export const userFromDTO = ({
  id,
  coach,
  username,
  status,
  email,
  school,
  district,
  profile,
  roles,
  coachee_type,
  is_deleted,
  is_smart,
  is_smart_default,
  smartCoach,
  years_coached,
  lastLoginMs,
  intercom_hash,
  activityStreamToken,
  grades,
  content_areas,
  userSchools,
  exclude_from_reports,
}: UserDTO): User => {
  const user = new User(
    id,
    username,
    profile,
    coachee_type ? getUserTypeFromDTO(coachee_type) : UserType.LEARNER,
    status,
    email,
    district ? districtDTOFromAPIResponse(district) : undefined,
    school,
    roles ? getRolesFromDTO(roles) : [],
    booleanFromBooleanNumber(is_deleted),
    booleanFromBooleanNumber(is_smart),
    is_smart_default ? booleanFromBooleanNumber(is_smart_default) : false,
    smartCoach,
    years_coached,
    coach || null,
    profile.created_at,
    coachee_type || null,
    lastLoginMs || null,
    exclude_from_reports
      ? booleanFromBooleanNumber(exclude_from_reports)
      : false,
    intercom_hash || undefined,
    activityStreamToken || undefined,
    grades || undefined,
    content_areas || undefined,
    userSchools || undefined
  );
  return user;
};
