<ng-container *ngIf="portfolioLoaded; else loading">
  <ng-container *ngIf="permissionEnabled; else noPermission">
    <div class="container container-main">
      <app-general-header [title]="'Portfolio'" />
      <div class="card border-0 px-5 py-4">
        <div *ngIf="searchBarEnabled" class="w-25">
          <app-user-select
            [districtId]="districtSearchId"
            [defaultUsers]="profileUser ? [profileUser] : null"
            (selectedUser)="handleUserSearchOutput($event)" />
        </div>
        <div class="card-body mt-3">
          <div class="row justify-content-start">
            <div
              *ngIf="currentUser && currentUser.profile"
              class="d-flex flex-column justify-content-center col-12 col-xl-3 text-center">
              <div class="mx-auto">
                <app-avatar
                  [profile]="profileUser.profile"
                  [avatarSize]="'xxx-large'"
                  [profileLinkActive]="false" />
              </div>
              <div class="fs-29 fw-800 mt-2" id="profile-full-name">
                {{ profileUser.profile.first_name }}
                {{ profileUser.profile.last_name }}
              </div>
              <div class="mt-2 d-none" id="profile-email">
                {{ profileUser.email }}
              </div>
            </div>
            <div
              *ngIf="portfolioData"
              class="justify-content-between col-12 col-xl-8 col-xxl-7 mt-4 mt-xl-0 ms-xxl-5">
              <div class="row justify-content-center">
                <div
                  class="col-12 col-sm-7 col-lg-4 col-xxl-4 align-self-center">
                  <app-quick-stat
                    [label]="'Coaching Sessions'"
                    [link]="
                      '/coaching?filters[coachee]=' +
                      profileUser.id +
                      '&filters[school_year]=' +
                      currentSchoolYearId +
                      '&filters[district_id]=' +
                      profileUser.district?.id +
                      '&me_mode=0'
                    "
                    [stat]="portfolioData.logCount" />
                </div>
                <div class="col-12 col-sm-7 col-lg-4 col-xxl-4 my-4 my-lg-0">
                  <app-quick-stat
                    [label]="'Celebrations'"
                    [link]="'/celebrations'"
                    [stat]="
                      selectedTopic ? '-' : portfolioData.celebrationCount
                    " />
                </div>
                <div class="col-12 col-sm-7 col-lg-4 col-xxl-4">
                  <app-quick-stat
                    [label]="'Badges'"
                    [link]="portfolioLink"
                    [stat]="portfolioData.badgeCount" />
                </div>
              </div>
            </div>
          </div>
          <div id="e2l-report-row" class="row bg-secondary rounded p-1 mt-3">
            <h2
              (click)="this.isCollapsed = !this.isCollapsed"
              [style.cursor]="'pointer'"
              class="fs-19 w-100 fw-800 text-body-secondary text-center mb-0 py-2">
              User Details
              <i
                class="bi bi-caret-{{
                  isCollapsed ? 'up' : 'down'
                }}-fill ms-3"></i>
            </h2>
            <div
              class="px-5 py-3"
              style="background-color: #fff"
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isCollapsed">
              <div class="row">
                <div class="col">
                  <div class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >Email:</label
                    >
                    {{ profileUser.email }}
                  </div>
                  <div class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >User ID:</label
                    >
                    {{ profileUser.profile.user_id }}
                  </div>
                  <div class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >Date Created:</label
                    >
                    {{ profileUser.profile.created_at * 1000 | date : "short" }}
                  </div>
                  <div *ngIf="profileUser.lastLoginMs" class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >Last login:</label
                    >
                    {{ profileUser.lastLoginMs | date : "short" }}
                  </div>
                  <div class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >User Type:</label
                    >
                    {{ profileUser.coacheeType }}
                  </div>
                  <div
                    class="d-flex"
                    *ngIf="isE2L && profileUser.roles.length > 0">
                    <label class="text-body-secondary me-3 fw-800"
                      >Roles:</label
                    >
                    <div class="small p-1">
                      {{ profileUser.roles.join(", ") }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >District:</label
                    >
                    {{ profileUser.district?.title }}
                  </div>
                  <div class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >School:</label
                    >
                    {{ profileUser.school?.title }}
                  </div>
                  <div class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >Job Title:</label
                    >
                    {{ profileUser.profile.title }}
                  </div>
                  <div class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >Coach:</label
                    >
                    {{ profileUser.coach?.username }}
                  </div>
                  <div
                    *ngIf="userCohorts && userCohorts.length > 0"
                    class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >Cohorts:</label
                    >
                    <ng-container
                      *ngFor="let cohort of userCohorts; let last = last">
                      {{ cohort }}<span *ngIf="!last">, </span>
                    </ng-container>
                  </div>
                  <div class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >Years Coached:</label
                    >
                    {{ profileUser.yearsCoached }}
                  </div>
                  <div class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >Grades:</label
                    >
                    <ng-container
                      *ngFor="let grade of profileUser.grades; let last = last">
                      {{ grade.title }}<span *ngIf="!last">, </span>
                    </ng-container>
                  </div>
                  <div class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >Content Areas:</label
                    >
                    <ng-container
                      *ngFor="
                        let area of profileUser.contentAreas;
                        let last = last
                      ">
                      {{ area.title }}<span *ngIf="!last">, </span>
                    </ng-container>
                  </div>
                  <div class="mb-2">
                    <label class="text-body-secondary me-3 fw-800"
                      >Secondary Schools:</label
                    >
                    <ng-container
                      *ngFor="let school of this.userSchools; let last = last">
                      {{ school.title }}<span *ngIf="!last">, </span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="portfolioData && profileUser">
            <div class="row mt-5 mb-4">
              <app-evidence-progress-bar
                [progressData]="portfolioData.evidenceProgress" />
            </div>
            <div class="row">
              <strong class="mt-2">All Competencies</strong>
              <div
                *ngIf="topics && topics.length > 1"
                class="topics-wrapper my-3">
                <app-topic-select
                  #topicSelect
                  [label]="'Topic'"
                  [topics]="topics"
                  (selectedTopic)="filterTopic($event)"></app-topic-select>
              </div>
            </div>
            <app-portfolio-competencies
              [competencyIds]="portfolioData.competencyIds"
              [userId]="profileUser.id" />
          </div>
        </div>
      </div>
    </div>
    <app-evidence-summary
      *ngIf="viewingEvidenceId"
      ngbTooltip="Review evidence"
      container="body"
      [evidenceId]="viewingEvidenceId"
      [autoOpenModal]="true"></app-evidence-summary>
  </ng-container>
  <ng-template #noPermission>
    <app-permission-denied />
  </ng-template>
</ng-container>
<ng-template #loading>
  <app-loader />
</ng-template>
