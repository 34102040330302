import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KpiReportFilter } from 'src/app/common/dtos/reports.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { DetailedPieData } from '../../../../shared/components/reports/report-controller/series-helper';

type DisplayData = DetailedPieData & {
  color: string;
};

@Component({
  selector: 'app-absence-types-table',
  templateUrl: './absence-types-table.component.html',
  styleUrls: ['./absence-types-table.component.scss'],
})
export class AbsenceTypesTableComponent implements OnChanges {
  @Input() externalData: DetailedPieData[];

  @Input() filters: KpiReportFilter[];

  @Input() colors: string[];

  @Input() limitDataPresentation = 0;

  hasData = false;

  primaryData: DetailedPieData[];

  displayData?: DisplayData[];

  constructor(private apiService: APICoreService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filters']) {
      this.getPrimaryData();
      this.limitData();
    }
    if (changes['colors']) {
      // For very large sets of data
      this.colors = [
        ...changes['colors'].currentValue,
        ...changes['colors'].currentValue,
      ];
    }
    if (changes['externalData']) {
      this.clearDisplayData();
      if (this.primaryData) {
        this.mergeData();
        this.limitData();
      }
    }
  }

  clearDisplayData() {
    this.hasData = false;
    if (this.displayData) {
      delete this.displayData;
    }
  }

  getPrimaryData() {
    this.clearDisplayData();
    const params = {
      id: 'absence-type-counts',
      dataset: 'fct_coaching_session',
      dimension: 'absence_type_name',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: this.filters,
    };
    this.apiService
      .getRequest('reporting/custom', params)
      .subscribe((value) => {
        if (value.datasets.length === 1) {
          this.primaryData = (value.datasets[0].data as number[]).map(
            (dataPoint, idx) => ({
              name: value.labels[idx],
              groupId: value.labels[idx],
              value: dataPoint,
              labelId: value.label_ids[idx],
            })
          );

          if (this.externalData) {
            this.mergeData();
          }

          this.limitData();
        }
      });
  }

  limitData() {
    if (this.displayData && this.limitDataPresentation > 0) {
      this.displayData = this.displayData.filter(
        (_eachItem: DisplayData, index: number) =>
          index < this.limitDataPresentation
      );
    }
  }

  mergeData() {
    this.displayData = this.externalData.map(
      (dataPoint: DetailedPieData, idx: number) => ({
        ...dataPoint,
        color: dataPoint.color || this.colors[idx],
      })
    );
    this.hasData = true;
  }
}
