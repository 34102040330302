import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadType } from '../../enums/upload-type.enum';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfilePictureFileService extends FileService {
  override uploadType = UploadType.USER_PROFILE_PICTURE;

  userId: number | null = null;

  setUserId(id: number | null) {
    this.userId = id;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.apiService.postFormRequest(
      `users/${this.userId}/profile-picture`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  deleteProfilePicture(): Observable<void> {
    return this.apiService.deleteRequest(`users/${this.userId}/upload`);
  }
}
