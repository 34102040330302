<app-modal
  #modal
  hiddenModal="closeModal"
  [modalConfig]="{
    titleText: 'Find Event',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    showFooter: false,
    options: {
      size: 'xl'
    }
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div class="p-4">
    <div class="row mb-4">
      <div class="col col-md-4">
        <input
          #searchText
          type="search"
          class="form-control"
          placeholder="Search"
          aria-label="Search"
          (input)="search($event)" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="d-flex align-items-center">
        <app-applied-filters
          [pdListSearchOptions]="currentFilters"
          filterType="pd-list"
          (filterRemoved)="removeFilter($event)"
          (filterListRemoved)="removeFilterFromList($event)" />
        <button
          (click)="pdFilterModal.pdFilterModal.open()"
          class="btn btn-link">
          Edit Filters
        </button>
      </div>
    </div>
    <ng-container *ngFor="let session of sessions">
      <app-pd-list-item
        [session]="session"
        [userId]="user.id"
        (sessionCopied)="copySession($event)"
        [context]="'copy'" />
    </ng-container>
    <div
      *ngIf="listMeta && listMeta.pageCount > 1"
      class="d-flex justify-content-center catalog-pagination">
      <ngb-pagination
        [(page)]="currentFilters.page"
        [pageSize]="currentFilters.per_page"
        [collectionSize]="listMeta.totalCount"
        (pageChange)="getSessions()"
        [maxSize]="5"
        [ellipses]="true"></ngb-pagination>
    </div>
  </div>
</app-modal>

<app-pd-filter-modal
  #pdFilterModal
  [context]="'copy'"
  [user]="user"
  [initialFilters]="currentFilters"
  [settings]="settings"
  (setFilters)="applyFilters($event)" />
