import { Pipe, PipeTransform } from '@angular/core';
import { DeliverableStateModel } from 'src/app/private/shared/state/implementation-plan/implementation-plan.state';
import { SchoolYearDTO } from '../dtos/school.dto';

@Pipe({
  name: 'checkDeliverablesWithinYear',
  pure: true,
})
export class CheckDeliverablesWithinYearPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(
    deliverables: DeliverableStateModel[],
    selectedSchoolYear: SchoolYearDTO | null
  ): DeliverableStateModel[] {
    if (!selectedSchoolYear) {
      return deliverables.map((deliverable) => {
        deliverable.hidden = false;
        return deliverable;
      });
    }

    const selectedSchoolYearStartDate = new Date(selectedSchoolYear.start_date);
    const selectedSchoolYearEndDate = new Date(selectedSchoolYear.end_date);

    return deliverables.filter((deliverable) => {
      // const deliverableStartDate = new Date(deliverable.start_date);
      const deliverableDueDate = new Date(deliverable.due_date);

      // check if deliverable is in school year
      if (
        deliverableDueDate >= selectedSchoolYearStartDate &&
        deliverableDueDate <= selectedSchoolYearEndDate
      ) {
        deliverable.hidden = false;
      } else {
        deliverable.hidden = true;
      }

      // check if any action item is in school year
      if (deliverable.actionItems.length) {
        let actionItemHidden = false;
        deliverable.actionItems.forEach((item) => {
          const actionItemDueDate = new Date(item.due_date);
          actionItemHidden =
            actionItemDueDate > selectedSchoolYearEndDate ||
            actionItemDueDate < selectedSchoolYearStartDate;
          item.hidden = true;
        });
        if (!actionItemHidden) {
          deliverable.hidden = actionItemHidden;
        }
      }

      return !deliverable.hidden;
    });
  }
}
