/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { userDTOFromAPIResponse } from 'src/app/private/shared/helpers/translators/user.translators';
import { UserPusherService } from 'src/app/private/shared/services/pusher/user.pusher.service';
import { SchoolSearchService } from 'src/app/private/shared/services/school-search/school-search.service';
import { DistrictDTO } from '../../dtos/district.dto';
import { SchoolDTO } from '../../dtos/school.dto';
import { StorageService } from '../../services/storage/storage.service';
import { User } from './user.model';
import { UserService } from './user.service';
import { userFromDTO } from './user.utilities';

@State<UserStateModel>({
  name: 'user',
  defaults: {
    hasData: false,
    isLoading: false,
    user: null,
    impersonated: false,
  },
})
export class FetchUser {
  static readonly type = '[User] Fetch User';
}

export class FetchUserSchools {
  static readonly type = '[User] Fetch User Schools';
}

export interface UserStateModel {
  hasData: boolean;
  isLoading: boolean;
  user: User | null;
  impersonated: boolean;
}

export class UpdateUserDistrict {
  static readonly type = '[User] Update District';

  constructor(public district: DistrictDTO) {}
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    hasData: false,
    isLoading: false,
    user: null,
    impersonated: false,
  },
})
@Injectable({
  providedIn: 'root',
})
export class UserState {
  constructor(
    private userService: UserService,
    private schoolSearchService: SchoolSearchService,
    private userPusherService: UserPusherService
  ) {}

  @Selector()
  static getUser(state: UserStateModel) {
    return state.user;
  }

  @Selector()
  static isLoading(state: UserStateModel) {
    return state.isLoading;
  }

  @Selector()
  static hasData(state: UserStateModel) {
    return state.hasData;
  }

  @Action(FetchUserSchools)
  fechUserSchools(ctx: StateContext<UserStateModel>) {
    const currentUser = ctx.getState().user as User;
    this.schoolSearchService
      .getAssignedSchools(currentUser.id)
      .subscribe((schools: SchoolDTO[]) => {
        if (schools && schools.length > 1) {
          currentUser.secondarySchools = schools;
        } else if (currentUser.secondarySchools) {
          delete currentUser.secondarySchools;
        }
        StorageService.storeItem('user', currentUser);
        ctx.patchState({
          user: currentUser,
        });
      });
  }

  @Action(FetchUser)
  fetchUser(ctx: StateContext<UserStateModel>) {
    ctx.patchState({
      isLoading: true,
    });
    const user = StorageService.getItem('user');

    if (user !== null) {
      const impersonated =
        StorageService.getItem('isImpersonatingUser') || false;
      ctx.patchState({
        user,
        isLoading: false,
        hasData: true,
        impersonated,
      });
    }
    return this.userService.fetchUser().pipe(
      tap((result) => {
        if (result !== null) {
          const userDto = userDTOFromAPIResponse(result?.item);
          const userData = userFromDTO(userDto);
          StorageService.storeItem('user', userData);
          ctx.patchState({
            user: userData,
            isLoading: false,
            hasData: true,
          });
          ctx.dispatch(new FetchUserSchools());
        } else {
          ctx.patchState({
            user: null,
            hasData: false,
            isLoading: false,
          });
        }
      })
    );
  }

  // eslint-disable-next-line class-methods-use-this
  @Action(UpdateUserDistrict)
  updateUserDistrict(
    ctx: StateContext<UserStateModel>,
    action: UpdateUserDistrict
  ) {
    const user = ctx.getState().user as User;
    user.district = action.district;
    ctx.patchState({
      user,
    });
    StorageService.storeItem('user', user);
  }
}
