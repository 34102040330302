import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { UserState } from 'src/app/common/state/user/user.state';
import { userFromDTO } from 'src/app/common/state/user/user.utilities';
import { TopicDTO } from '../../shared/components/topic-select/topic-select.component';
import { CompetencyBadgeRubricDTO } from '../../shared/dtos/badge.dto';
import {
  AlertService,
  AlertType,
} from '../../shared/services/alert/alert.service';
import { BadgeService } from '../../shared/services/badge/badge.service';
import { PortfolioService } from '../../shared/services/portfolio/portfolio.service';

@Component({
  selector: 'app-portfolio-badges-page',
  templateUrl: './portfolio-badges-page.component.html',
  styleUrls: ['./portfolio-badges-page.component.scss'],
})
export class PortfolioBadgesPageComponent implements OnInit {
  @ViewChild('downloadBadgeTooltip') downloadBadgeTooltip: NgbTooltip;

  apiUrl: string;

  currentUser: User;

  permissionEnabled = false;

  userSelectEnabled = false;

  profileUser: User;

  badgeData?: CompetencyBadgeRubricDTO[];

  districtSearchId: number | null = null;

  topics: TopicDTO[];

  selectedTopic: number | undefined;

  loadingBadges = false;

  disableDownload = false;

  public isCollapsed: boolean[] = [];

  constructor(
    private store: Store,
    private portfolioService: PortfolioService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private badgeService: BadgeService,
    private alertService: AlertService
  ) {
    this.currentUser = this.store.selectSnapshot(UserState.getUser) as User;
    this.apiUrl = EnvironmentService.apiUrl();
  }

  ngOnInit(): void {
    if (this.currentUser) {
      this.route.params.subscribe((url) => {
        if (url['userId']) {
          this.getProfileUser(url['userId']);
        }
      });
    }
  }

  getProfileUser(userId: number) {
    this.loadingBadges = true;
    this.userService.fetchUserById(userId).subscribe((res) => {
      if (res) {
        this.profileUser = userFromDTO(res);
        this.checkPermissions();
        this.getRubricData();
        this.getBadges();
      }
    });
  }

  getRubricData() {
    if (this.profileUser) {
      this.portfolioService
        .getUserRubrics(this.profileUser.id)
        .subscribe((res) => {
          this.topics = res.items;
        });
    }
  }

  getBadges(rubricId: number[] = []) {
    if (rubricId.length) {
      this.portfolioService
        .getCompetencyBadgesForTopic(this.profileUser.id, rubricId)
        .subscribe((res) => {
          this.badgeData = res;
          this.setCollapseStatus();
          this.loadingBadges = false;
        });
    } else {
      this.portfolioService
        .getCompetencyBadges(this.profileUser.id)
        .subscribe((res) => {
          this.badgeData = res;
          this.setCollapseStatus();
          this.loadingBadges = false;
        });
    }
  }

  setCollapseStatus() {
    this.badgeData?.forEach((comp) => {
      if (comp.badges.some((badge) => badge.isEarned)) {
        comp.collapsed = false;
      } else {
        comp.collapsed = true;
      }
    });
  }

  checkPermissions() {
    if (
      this.currentUser.roles.includes(
        UserRole.COACH ||
          UserRole.DISTRICT_ADMIN ||
          UserRole.OPERATOR ||
          UserRole.E2L_EMPLOYEE ||
          UserRole.ESUITE_ADMIN ||
          UserRole.SCHOOL_ADMIN
      ) ||
      this.profileUser.id === this.currentUser.id
    ) {
      this.permissionEnabled = true;
    }
    if (
      this.currentUser.roles.includes(
        UserRole.COACH ||
          UserRole.DISTRICT_ADMIN ||
          UserRole.OPERATOR ||
          UserRole.E2L_EMPLOYEE ||
          UserRole.ESUITE_ADMIN ||
          UserRole.SCHOOL_ADMIN
      )
    ) {
      this.userSelectEnabled = true;
    }
  }

  handleUserSearchOutput(user: UserLiteDTO | null) {
    this.badgeData = [];
    this.selectedTopic = undefined;
    if (user) {
      this.router.navigate([`/portfolio/${user.id}/badges`]);
      this.getProfileUser(user.id);
    } else {
      this.router.navigate([`/portfolio/${this.currentUser.id}/badges`]);
      this.getProfileUser(this.currentUser.id);
    }
  }

  filterTopic(topic: TopicDTO) {
    if (this.profileUser) {
      if (topic === undefined) {
        this.getBadges();
      } else {
        this.getBadges([topic.id]);
        this.selectedTopic = topic.id;
      }
    }
  }

  downloadBadgesCSV() {
    this.disableDownload = true;
    this.badgeService.downloadBadgesCSV(this.profileUser.id).subscribe({
      error: () => {
        this.downloadBadgeTooltip.close();
        this.disableDownload = false;
      },
      next: (res) => {
        if (res.has_results) {
          // res.file_name should always start with a slash, but need to be sure for the link to work properly
          if (!res.file_name.startsWith('/')) {
            res.file_name = `/${res.file_name}`;
          }
          window.open(
            `${EnvironmentService.apiUrl()}${res.file_name}`,
            '_blank'
          );
        } else {
          this.alertService.showAlert(
            'There was an error downloading badges',
            undefined,
            AlertType.DANGER
          );
        }
        this.disableDownload = false;
      },
    });
  }
}
