import { UploadType } from '../../enums/upload-type.enum';
import { BadgeFileService } from './badge.file.service';
import { EvidenceFileService } from './evidence.file.service';
import { FileService } from './file.service';
import { LogFileService } from './log.file.service';
import { PdEventEvidenceFileService } from './pd-event-evidence.file.service';
import { PlanActionItemFileService } from './plan-action-item.file.service';
import { PlanDeliverableFileService } from './plan-deliverable.file.service';
import { ResourceFileService } from './resource.file.service';
import { StandardIconFileService } from './standard-icon.file.service';
import { UserProfilePictureFileService } from './user-profile-picture.file.service';
import { VideoFileService } from './video.file.service';

export const FileServiceFactory: {
  [key in UploadType]: typeof FileService;
} = {
  [UploadType.USER_UPLOAD]: FileService,
  [UploadType.RESOURCE_ATTACHMENT]: ResourceFileService,
  [UploadType.EVIDENCE]: EvidenceFileService,
  [UploadType.VIDEO]: VideoFileService,
  [UploadType.LOG]: LogFileService,
  [UploadType.BADGE]: BadgeFileService,
  [UploadType.MICROCREDENTIAL_BADGE]: BadgeFileService,
  [UploadType.STANDARD_ICON]: StandardIconFileService,
  [UploadType.DELIVERABLE_ATTACHMENT]: PlanDeliverableFileService,
  [UploadType.ACTION_ITEM_ATTACHMENT]: PlanActionItemFileService,
  [UploadType.USER_PROFILE_PICTURE]: UserProfilePictureFileService,
  [UploadType.PD_EVENT_EVIDENCE]: PdEventEvidenceFileService,
};
