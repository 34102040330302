import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { cohortDTOFromApiResponse } from 'src/app/private/shared/helpers/translators/cohort.translators';
import { userDTOFromAPIResponse } from 'src/app/private/shared/helpers/translators/user.translators';
import { CohortAPIResponse } from 'src/app/private/shared/types/responses/cohort.responses';

import { UserSearchParams } from 'src/app/private/shared/components/roster-users/roster-users.helper';
import { CohortDTO } from '../../dtos/cohort.dto';
import { GradeDTO } from '../../dtos/grade.dto';
import { ContentAreaDTO, SchoolDTO } from '../../dtos/school.dto';
import { APICoreService } from '../../services/api-core/api-core.service';
import {
  SingleApiResponse,
  UserDtoApiResponse,
} from '../../types/responses/responses';
import {
  CreateUserPayloadDTO,
  UserBulkPayload,
  UserDTO,
  UserLiteDTO,
} from './user.dto';

@Injectable({ providedIn: 'root' })
export class UserService {
  cachedUsers: UserDTO[] = [];

  constructor(private apiService: APICoreService) {}

  fetchUser(): Observable<SingleApiResponse> {
    return this.apiService.getRequest('user/me').pipe(
      catchError((err) => {
        throw err;
      })
    );
  }

  fetchUserById(userId: number): Observable<UserDTO> {
    return this.apiService
      .getRequest(`users/${userId}`)
      .pipe(map((res) => userDTOFromAPIResponse(res.item)));
  }

  fetchUsers(params: UserSearchParams): Observable<UserDtoApiResponse> {
    return this.apiService.getRequest('users', params);
  }

  fetchUserPermissions() {
    return this.apiService.getRequest('user/permissions?per_page=1000');
  }

  updateUserNote(coacheeId: number, notesObject: object): Observable<boolean> {
    return this.apiService.postRequest(
      `growelab/coachees/${coacheeId}/notes`,
      notesObject
    );
  }

  fetchCohorts(userId: number): Observable<CohortDTO[]> {
    return this.apiService.getRequest(`user-cohorts/${userId}`).pipe(
      map((res) => {
        if (res.items && res.items.length > 0) {
          return res.items.map((apiResponse: CohortAPIResponse) =>
            cohortDTOFromApiResponse(apiResponse)
          );
        }
        return [];
      })
    );
  }

  createUser(newUser: CreateUserPayloadDTO): Observable<UserDTO> {
    return this.apiService.postRequest('growelab/user', newUser).pipe(
      map((res) => userDTOFromAPIResponse(res.item)),
      catchError((err) => {
        throw err;
      })
    );
  }

  deleteUser(userId: number) {
    return this.apiService.deleteRequest(`users/${userId}`);
  }

  fetchCoaches(districtIds: number[]): Observable<UserLiteDTO[]> {
    const districtIdsString = districtIds.join('&district_ids[]=');
    return this.apiService
      .getRequest(
        `users/search?role=egroweCoach&per_page=1000&district_ids[]=${districtIdsString}`
      )
      .pipe(map((res) => res.items));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateUser(userId: number, payload: any) {
    return this.apiService.putRequest(`users/${userId}`, payload).pipe(
      catchError((err) => {
        throw err;
      })
    );
  }

  addUserToCache(userToAdd: UserDTO) {
    const index = this.cachedUsers.findIndex(
      (user) => user.id === userToAdd.id
    );
    if (index !== -1) {
      this.cachedUsers[index] = userToAdd;
    } else {
      this.cachedUsers.push(userToAdd);
    }
  }

  getCachedUser(userId: number) {
    return this.cachedUsers.find((user) => user.id === userId);
  }

  getGrades() {
    return this.apiService.getRequest('grade-levels', { exclude_na: true });
  }

  getContentAreas() {
    return this.apiService.getRequest('content-areas');
  }

  updateContentAreas(userId: number, contentAreas: ContentAreaDTO[]) {
    const areaIds = contentAreas.map((area) => area.id);
    return this.apiService.postRequest('content-area/many', {
      user_id: userId,
      user_content_areas: areaIds,
    });
  }

  updateGrades(userId: number, grades: GradeDTO[]) {
    const gradeIds = grades.map((grade) => grade.id);
    return this.apiService.postRequest('user-grade/many', {
      user_id: userId,
      user_grades: gradeIds,
    });
  }

  updateAdditionalSchools(userId: number, schools: SchoolDTO[]) {
    const schoolIds = schools.map((school) => school.id);
    return this.apiService.postRequest(`egrowe/user_school/${userId}`, {
      schools: schoolIds,
    });
  }

  updateUserCoacheeType(userId: number, type: string) {
    return this.apiService.postRequest(`egrowe/user/${userId}/coachee_type`, {
      coachee_type: type,
    });
  }

  updateUserCoach(userId: number, coachId: number) {
    return this.apiService.postRequest(`egrowe/user/${userId}/coach`, {
      coach_id: coachId,
    });
  }

  updateBulkUsers(payload: UserBulkPayload) {
    return this.apiService.putRequest('users/bulk', payload);
  }
}
