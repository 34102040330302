import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { User } from 'src/app/common/state/user/user.model';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { PdListFilterDTO, PdSettings } from '../../../dtos/pd.dto';
import { UserSelectComponent } from '../../user-select/user-select.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-pd-filter-modal',
  templateUrl: './pd-filter-modal.component.html',
  styleUrl: './pd-filter-modal.component.scss',
})
export class PdFilterModalComponent implements OnInit {
  @ViewChild('pdFilterModal') pdFilterModal: ModalComponent;

  @ViewChild('typeSelect') typeSelect: NgSelectComponent;

  @ViewChild('facilitatorSelect') facilitatorSelect: UserSelectComponent;

  @ViewChild('categorySelect') categorySelect: NgSelectComponent;

  @Input() initialFilters: PdListFilterDTO;

  @Input() user: User;

  @Input() settings: PdSettings;

  @Input() context: 'normal' | 'copy' = 'normal';

  @Input() isE2L: boolean;

  @Output() readonly setFilters: EventEmitter<PdListFilterDTO> =
    new EventEmitter<PdListFilterDTO>();

  currentFilters: PdListFilterDTO;

  ngOnInit(): void {
    if (this.initialFilters) {
      this.setInitialFilters();
    }
  }

  setInitialFilters() {
    this.currentFilters = {} as PdListFilterDTO;
    this.currentFilters = deepCopy(this.initialFilters);
    if (this.facilitatorSelect) {
      this.facilitatorSelect.internalSelectedUserList =
        this.currentFilters.facilitators || [];
    }
  }

  handleTypeSelect(type: string) {
    if (type && !this.currentFilters.types.includes(type)) {
      this.currentFilters.types.push(type);
    }
    this.typeSelect.handleClearClick();
  }

  handleCategorySelect(category: string) {
    if (category && !this.currentFilters.categories.includes(category)) {
      this.currentFilters.categories.push(category);
    }
    this.categorySelect.handleClearClick();
  }

  clearFilters() {
    this.facilitatorSelect.reset();
  }

  closeModal(saveFilters = false) {
    if (saveFilters) {
      this.setFilters.emit(this.currentFilters);
    }
    this.clearFilters();
    this.pdFilterModal.close();
  }

  removeType(type: string) {
    this.currentFilters.types = this.currentFilters.types.filter(
      (item) => item !== type
    );
  }

  removeCategory(category: string) {
    this.currentFilters.categories = this.currentFilters.categories.filter(
      (item) => item !== category
    );
  }
}
