<ng-container *ngIf="canCreate">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="col-9">
        <h1 class="fs-25 fw-bold">Learning Sessions</h1>
      </div>
      <div class="col-3 col-md-auto">
        <app-action-buttons />
      </div>
    </div>

    <div class="card border-0">
      <div class="card-body px-5 py-4">
        <h2 class="fs-25 fw-bold">
          Create a Session<ng-container *ngIf="currentScreen === 'eventDetails'"
            >: Event Details</ng-container
          ><ng-container *ngIf="currentScreen === 'sessionDetails'"
            >: Session Details</ng-container
          >
        </h2>
        <hr class="border border-tertiary border-1" />
        <ng-container *ngIf="currentScreen === 'newOrExisting'">
          <div
            style="min-height: 500px"
            class="w-100 d-flex align-items-center justify-content-center">
            <div>
              <p class="fs-22 mb-4 text-center">
                Create a new event or copy an existing event?
              </p>
              <div
                class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                <button
                  (click)="currentScreen = 'eventDetails'"
                  type="button"
                  class="btn btn-primary fw-bold mb-2"
                  style="width: 175px">
                  New Event
                </button>
                <span class="fw-bold mb-2 color-tertiary mx-3">OR</span>
                <button
                  (click)="copyPdSessionModal.modal.open()"
                  type="button"
                  class="btn btn-primary fw-bold mb-2"
                  style="min-width: 175px">
                  Copy Existing Event
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <form [formGroup]="form">
          <ng-container *ngIf="currentScreen === 'eventDetails'">
            <div *ngIf="eventReference" class="mt-4 mb-3">
              <span class="fw-bold">Event Reference:</span> {{ eventReference }}
            </div>
            <div class="row mb-5 flex-column flex-lg-row">
              <div class="col">
                <label for="title" class="form-label">Title</label>
                <div class="input-group">
                  <input
                    formControlName="title"
                    type="text"
                    class="form-control"
                    id="title"
                    aria-label="title"
                    [ngClass]="{
                      'is-invalid': eventFormSubmitted && f['title'].errors
                    }" />
                </div>
                <label for="short-description" class="form-label"
                  >Short Description</label
                >
                <div class="input-group">
                  <textarea
                    formControlName="shortDescription"
                    class="form-control"
                    id="short-description"
                    aria-label="Short Description"
                    [ngClass]="{
                      'is-invalid':
                        eventFormSubmitted && f['shortDescription'].errors
                    }"></textarea>
                </div>
                <label class="form-label">Image</label>
                <div class="mb-3">
                  <ng-container *ngIf="!imagePath; else imagePreview">
                    <app-file-upload
                      #uploadComponent
                      [uploadType]="uploadType"
                      [style]="uploadStyle"
                      [multiple]="true"
                      (outputFiles)="updateImage($event)" />
                  </ng-container>
                  <ng-template #imagePreview>
                    <img
                      src="{{ imagePath }}"
                      alt="Session Image"
                      class="session-image" />
                    <button
                      (click)="deleteImage()"
                      aria-label="Delete image"
                      class="btn btn-delete"
                      type="button"
                      [autoClose]="'outside'"
                      ngbTooltip="Delete Image">
                      <i class="bi bi-trash"></i>
                    </button>
                  </ng-template>
                </div>
                <div class="mb-3">
                  <label for="type" class="form-label">Type</label>
                  <ng-select
                    formControlName="type"
                    id="type"
                    autocomplete="off"
                    [clearable]="false"
                    [placeholder]="'Select a type'"
                    [ngClass]="{
                      'is-invalid': eventFormSubmitted && f['type'].errors
                    }">
                    <ng-container *ngFor="let type of pdSessionTypes">
                      <ng-option [value]="type.value">{{
                        type.label
                      }}</ng-option>
                    </ng-container>
                  </ng-select>
                </div>
                <div class="mb-3">
                  <label for="virtual" class="form-label">Virtual/OnSite</label>
                  <ng-select
                    formControlName="virtual"
                    id="virtual"
                    autocomplete="off"
                    [clearable]="false"
                    [placeholder]="'Select an option'"
                    [ngClass]="{
                      'is-invalid': eventFormSubmitted && f['virtual'].errors
                    }">
                    <ng-container *ngFor="let option of virtualOptions">
                      <ng-option [value]="option.value">{{
                        option.label
                      }}</ng-option>
                    </ng-container>
                  </ng-select>
                </div>
                <div class="mb-3">
                  <label for="attendee-resources" class="form-label"
                    >Attendee Resources / Notes</label
                  >
                  <app-wysiwyg-editor
                    #attendeeNotesEditor
                    [editorContent]="copiedNotes"
                    [version]="'mini'"></app-wysiwyg-editor>
                </div>
              </div>
              <div class="col">
                <div class="mb-5">
                  <h3 class="fs-19 mt-2 mb-0 fw-bold">Credits</h3>
                  <hr class="border border-tertiary border-1" />
                  <ng-container *ngIf="settings">
                    <ng-container
                      *ngIf="
                        settings.credits?.length === 0;
                        else addCreditsTemplate
                      ">
                      <p>
                        No credits are available for your district. Please
                        contact your district operator.
                      </p>
                    </ng-container>
                    <ng-template #addCreditsTemplate>
                      <ng-container
                        formArrayName="credits"
                        *ngFor="let credit of credits.controls; let i = index">
                        <div class="d-flex mb-2">
                          <ng-container [formGroupName]="i">
                            <div class="col-8">
                              <ng-select
                                #creditTypeSelect
                                formControlName="creditType"
                                id="credit-type"
                                autocomplete="off"
                                [clearable]="false"
                                [placeholder]="'Select a credit type'"
                                [ngClass]="{
                                  'is-invalid':
                                    eventFormSubmitted &&
                                    credit.get('creditType')?.errors
                                }">
                                <ng-container
                                  *ngFor="let credit of settings.credits">
                                  <ng-option
                                    [value]="credit.title"
                                    [disabled]="
                                      isCreditTypeSelected(credit.title, i)
                                    "
                                    >{{ credit.title }}</ng-option
                                  >
                                </ng-container>
                              </ng-select>
                            </div>
                            <div class="col-3 ps-2">
                              <input
                                formControlName="creditAmount"
                                type="number"
                                class="form-control"
                                id="credit-amount"
                                aria-label="Credit Amount"
                                [ngClass]="{
                                  'is-invalid':
                                    eventFormSubmitted &&
                                    credit.get('creditAmount')?.errors
                                }" />
                            </div>
                            <div class="col-1 d-flex align-items-center">
                              <button
                                (click)="deleteCredit(i)"
                                aria-label="Delete Credit button"
                                class="btn btn-delete btn-sm"
                                type="button"
                                [autoClose]="'outside'"
                                ngbTooltip="Delete Credit">
                                <i class="bi bi-trash"></i>
                              </button>
                            </div>
                          </ng-container>
                        </div>
                        <div
                          *ngIf="
                            credit
                              .get('creditAmount')
                              ?.hasError('nonNegativeInteger') &&
                            credit.get('creditAmount')?.touched
                          ">
                          <p class="fs-13 mb-2 text-danger">
                            Credit amount must be a non-negative whole number.
                          </p>
                        </div>
                      </ng-container>
                      <button
                        (click)="addCredit()"
                        type="button"
                        class="btn btn-link fw-bold p-0 text-decoration-none">
                        <i class="bi bi-plus"></i>Create Credit
                      </button>
                    </ng-template>
                  </ng-container>
                </div>
                <div class="mb-5">
                  <h3 class="fs-19 mt-2 mb-0 fw-bold">Categories</h3>
                  <hr class="border border-tertiary border-1" />
                  <ng-container *ngIf="settings">
                    <ng-container
                      *ngIf="
                        settings.categories?.length === 0;
                        else addCategoriesTemplate
                      ">
                      <p>
                        No categories are available for your district. Please
                        contact your district operator.
                      </p>
                    </ng-container>
                    <ng-template #addCategoriesTemplate>
                      <ng-container
                        formArrayName="categories"
                        *ngFor="
                          let category of categories.controls;
                          let i = index
                        ">
                        <div class="d-flex mb-2">
                          <ng-container [formGroupName]="i">
                            <div class="col">
                              <ng-select
                                #categorySelect
                                formControlName="category"
                                id="category"
                                autocomplete="off"
                                [placeholder]="'Select a category'"
                                [clearable]="false"
                                [ngClass]="{
                                  'is-invalid':
                                    eventFormSubmitted &&
                                    category.get('category')?.errors
                                }">
                                <ng-container
                                  *ngFor="let category of settings.categories">
                                  <ng-option [value]="category.title">
                                    {{ category.title }}
                                  </ng-option>
                                </ng-container>
                              </ng-select>
                            </div>
                            <div class="col ps-2">
                              <ng-select
                                #categoryOptionSelect
                                formControlName="categoryOptions"
                                id="category-options"
                                autocomplete="off"
                                [placeholder]="'Select an option'"
                                [clearable]="false"
                                [ngClass]="{
                                  'is-invalid':
                                    eventFormSubmitted &&
                                    category.get('categoryOptions')?.errors
                                }">
                                <ng-container
                                  *ngFor="
                                    let option of getFilteredOptions(
                                      categories.controls[i].get('category')
                                        ?.value
                                    )
                                  ">
                                  <ng-option [value]="option.option_value">
                                    {{ option.option_value }}
                                  </ng-option>
                                </ng-container>
                              </ng-select>
                            </div>
                            <div class="col-1 d-flex align-items-center">
                              <button
                                aria-label="Delete Category button"
                                class="btn btn-delete btn-sm"
                                type="button"
                                (click)="deleteCategory(i)"
                                [autoClose]="'outside'"
                                ngbTooltip="Delete Category">
                                <i class="bi bi-trash"></i>
                              </button>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                      <button
                        (click)="addCategory()"
                        type="button"
                        class="btn btn-link fw-bold p-0 text-decoration-none">
                        <i class="bi bi-plus"></i>Create Category
                      </button>
                    </ng-template>
                  </ng-container>
                </div>
                <div class="mb-5">
                  <h3 class="fs-19 mt-2 mb-0 fw-bold">Competency Alignment</h3>
                  <hr class="border border-tertiary border-1" />
                  <app-competency-select
                    #selectCompetency
                    [districtIds]="districtIds"
                    [clearAfterSelection]="false"
                    [isMulti]="true"
                    [defaultCompetencies]="currentCompetencies"
                    (selectedCompetencyList)="addCompetencies($event)"
                    [placeholder]="'Select competency'" />
                </div>
              </div>
            </div>
            <hr class="border border-tertiary border-1 mb-4" />
            <div class="d-flex justify-content-between">
              <button
                *ngIf="usersDistrictHasSessions"
                type="button"
                class="btn btn-primary fw-bold"
                (click)="navigateToNewOrExisting()">
                <i class="bi bi-chevron-left"></i> Back
              </button>
              <button
                type="button"
                class="btn btn-primary fw-bold ms-auto"
                (click)="navigateToSessionDetails()">
                Next: Session Details
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="currentScreen === 'sessionDetails'">
            <div class="row mb-5 flex-column flex-lg-row">
              <div class="col">
                <div class="mb-3">
                  <label class="form-label">Date & Time</label>
                  <app-datepicker
                    #dateAndTimePicker
                    (dateSelectedUnixTimestamp)="updateDateTime($event)"
                    [initialDateUnixTimestamp]="tomorrowsDateTime"
                    [minDate]="tomorrowsDate"
                    [timepicker]="true"
                    [timezone]="defaultTimezone"></app-datepicker>
                  <ng-container *ngIf="dateAndTimeInvalid">
                    <p class="text-danger my-2">Please select a valid date.</p>
                  </ng-container>
                </div>
                <label for="location" class="form-label">Location</label>
                <div
                  *ngIf="
                    f['virtual'].value === 0;
                    else hardcodedVirtualLocation
                  "
                  class="input-group">
                  <input
                    formControlName="location"
                    type="text"
                    class="form-control"
                    id="location"
                    aria-label="location" />
                </div>
                <ng-template #hardcodedVirtualLocation>
                  <p>Virtual</p>
                </ng-template>
                <div *ngIf="f['virtual'].value === 1" class="mb-3">
                  <label for="virtual-link" class="form-label"
                    >Virtual Session Link</label
                  >
                  <div class="input-group">
                    <input
                      formControlName="virtualLink"
                      type="text"
                      class="form-control"
                      id="virtual-link"
                      aria-label="Virtual Session Link" />
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">Registration Window</label>
                  <div class="row align-items-center">
                    <div class="col">
                      <app-datepicker
                        (dateSelectedUnixTimestamp)="
                          updateRegistrationFromDate($event)
                        "
                        [initialDateUnixTimestamp]="todaysDateTime"
                        [minDate]="todaysDate"
                        [timezone]="defaultTimezone"></app-datepicker>
                    </div>
                    <div class="col-2">
                      <div class="text-center text-nowrap">to</div>
                    </div>
                    <div class="col">
                      <app-datepicker
                        (dateSelectedUnixTimestamp)="
                          updateRegistrationToDate($event)
                        "
                        [initialDateUnixTimestamp]="tomorrowsDateTime"
                        [minDate]="tomorrowsDate"
                        [timezone]="defaultTimezone"></app-datepicker>
                    </div>
                  </div>
                </div>
                <div>
                  <label for="attendance-cap" class="form-label"
                    >Attendance Cap</label
                  >
                  <div class="input-group" style="max-width: 60px">
                    <input
                      formControlName="attendanceCap"
                      type="number"
                      class="form-control"
                      id="attendance-cap"
                      aria-label="Attendance Cap"
                      [ngClass]="{
                        'is-invalid':
                          detailsFormSubmitted && f['attendanceCap'].errors
                      }" />
                  </div>
                  <div
                    *ngIf="
                      f['attendanceCap'].hasError('nonNegativeInteger') &&
                      f['attendanceCap'].touched
                    ">
                    <p class="fs-13 mb-2 text-danger">
                      Must be a non-negative whole number.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div *ngIf="isE2L" class="mb-5">
                  <h3 class="fs-19 mt-2 mb-0 fw-bold">Sharing (e2L only)</h3>
                  <hr class="border border-tertiary border-1" />
                  <div class="d-flex align-items-center mb-3">
                    <label class="form-check-label" for="is-shared-switch">
                      Share session across districts?
                    </label>
                    <button
                      aria-label="Share Across Districts"
                      class="form-switch btn-reset ms-2">
                      <input
                        id="is-shared-switch"
                        class="form-check-input cursor-pointer"
                        type="checkbox"
                        role="switch"
                        formControlName="isShared" />
                    </button>
                  </div>
                  <ng-container *ngIf="f['isShared'].value">
                    <div class="mb-3">
                      <div class="mb-2">
                        Share with these districts (default is share across all
                        districts):
                      </div>
                      <app-district-select
                        #shareWithDistrictSelect
                        (selectedDistrictList)="updateSharing($event, 1)"
                        [isMulti]="true"></app-district-select>
                    </div>
                    <div class="mb-5">
                      <div class="mb-2">Exclude from these districts:</div>
                      <app-district-select
                        #excludeFromDistrictSelect
                        (selectedDistrictList)="updateSharing($event, 0)"
                        [isMulti]="true"></app-district-select>
                    </div>
                  </ng-container>
                </div>
                <h3 class="fs-19 mt-2 mb-0 fw-bold">Facilitators</h3>
                <hr class="border border-tertiary border-1" />
                <app-user-select
                  [clearAfterSelection]="true"
                  (selectedUser)="addFacilitator($event)"></app-user-select>
                <div class="row mt-2">
                  <ng-container *ngFor="let facilitator of facilitators">
                    <div class="d-flex align-items-center col-6 my-1">
                      <app-avatar
                        [profile]="facilitator.profile"
                        [avatarSize]="'small'"></app-avatar>
                      <span class="ms-2">
                        {{ facilitator.profile.first_name }}
                        {{ facilitator.profile.last_name }}
                      </span>
                      <button
                        (click)="removeFacilitator(facilitator)"
                        aria-label="Remove facilitator button"
                        class="btn btn-delete btn-sm"
                        type="button"
                        ngbTooltip="Remove Facilitator"
                        [autoClose]="'outside'">
                        <i class="bi bi-x-circle-fill"></i>
                      </button>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <ng-container *ngIf="errorMessage">
              <div class="alert alert-danger text-start mt-3 mb-0" role="alert">
                {{ errorMessage }}
                <button
                  type="button"
                  class="btn-close"
                  (click)="errorMessage = ''"
                  data-bs-dismiss="alert"
                  aria-label="Close"></button>
              </div>
            </ng-container>
            <hr class="border border-tertiary border-1 mb-4" />
            <div class="d-flex justify-content-between flex-column flex-sm-row">
              <button
                type="button"
                class="btn btn-primary fw-bold mb-2 mb-sm-0"
                (click)="currentScreen = 'eventDetails'">
                <i class="bi bi-chevron-left"></i> Back to Event Details
              </button>
              <button
                [disabled]="submitLoading"
                type="button"
                class="btn btn-primary fw-bold"
                (click)="publishSession()">
                <span
                  *ngIf="submitLoading"
                  class="spinner-border spinner-border-sm me-1"></span>
                Publish Session
              </button>
            </div>
          </ng-container>
        </form>
      </div>
    </div>
  </div>

  <app-copy-pd-session-modal
    #copyPdSessionModal
    (sessionCopied)="copySession($event)"></app-copy-pd-session-modal>
</ng-container>
