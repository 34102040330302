/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { PlansService } from '../../services/plans/plans.service';
import {
  ActionItemAPIResponse,
  DeliverableAPIResponse,
  PhaseAPIResponse,
  PlanDetailsItemAPIResponse,
  StatusType,
} from '../../types/responses/plan.responses';
import {
  AddAssignment,
  AddPhase,
  CreateActionItem,
  CreateDeliverable,
  DeleteActionItem,
  DeleteAssignment,
  DeleteDeliverable,
  DeletePhase,
  EditActionItem,
  EditDeliverable,
  EditPhase,
  EditPlan,
  FetchPlanDetails,
  ToggleDeliverableExpandedState,
  TogglePhaseExpandedState,
  UpdatePhaseStatus,
} from './implementation-plan.actions';

// We store toggle state of each phase and deliverable because otherwise
// any time the state is updated, the expanded state will be reset due to how Angular handles the DOM
export interface ActionItemStateModel extends ActionItemAPIResponse {
  canEdit?: boolean;
  hidden?: boolean;
}

export interface DeliverableStateModel extends DeliverableAPIResponse {
  actionItems: ActionItemStateModel[];
  isExpanded?: boolean;
  canEdit?: boolean;
  hidden?: boolean;
}

export interface PhaseStateModel
  extends Omit<PhaseAPIResponse, 'deliverables'> {
  deliverables: DeliverableStateModel[];
  isExpanded?: boolean;
  canEdit?: boolean;
  deliverablesHidden?: boolean;
}

export interface PlanDetailsItemStateModel
  extends Omit<PlanDetailsItemAPIResponse, 'phases'> {
  phases: PhaseStateModel[];
  forbidden?: boolean;
}

const planDefaults: PlanDetailsItemStateModel = {
  id: 0,
  title: '',
  description: '',
  start_date: '',
  end_date: '',
  district_id: 0,
  phase_displayname: '',
  deliverable_displayname: '',
  actionitem_displayname: '',
  created_at: 0,
  updated_at: 0,
  is_deleted: 0,
  phases: [],
  forbidden: false,
};

@State<PlanDetailsItemStateModel>({
  name: 'planDetails',
  defaults: planDefaults,
})
@Injectable({
  providedIn: 'root',
})
export class ImplementationPlanState {
  user: User;

  constructor(
    private implementationPlanService: PlansService,
    private store: Store
  ) {}

  @Selector()
  static getPlanDetails(state: PlanDetailsItemStateModel) {
    return state;
  }

  @Action(FetchPlanDetails)
  fetchPlanDetails(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: FetchPlanDetails
  ) {
    let currentState = ctx.getState();
    if (payload !== currentState.id) {
      ctx.setState(planDefaults);
      this.implementationPlanService.getPlanDetails(payload).subscribe({
        error: (error) => {
          if (error.status === 403) {
            ctx.patchState({
              forbidden: true,
            });
          }
        },
        next: (response) => {
          if (response) {
            this.user = this.store.selectSnapshot(UserState.getUser) as User;
            ctx.setState(response.item);
            currentState = ctx.getState();
            ctx.patchState({
              phases: this.updatePhasePermissions(
                this.user.id,
                currentState.phases
              ),
            });
          }
        },
      });
    }
  }

  // Accordion Toggle State
  @Action(TogglePhaseExpandedState)
  togglePhaseExpandedState(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: TogglePhaseExpandedState
  ) {
    const currentState = ctx.getState();
    ctx.patchState({
      phases: currentState.phases.map((phase) => {
        if (phase.id === payload.phaseId) {
          return {
            ...phase,
            isExpanded: !phase.isExpanded,
          };
        }
        return phase;
      }),
    });
  }

  @Action(ToggleDeliverableExpandedState)
  toggleDeliverableExpandedState(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: ToggleDeliverableExpandedState
  ) {
    const currentState = ctx.getState();
    ctx.patchState({
      phases: currentState.phases.map((phase) => ({
        ...phase,
        deliverables: phase.deliverables.map((deliverable) => {
          if (deliverable.id === payload.deliverableId) {
            return {
              ...deliverable,
              isExpanded: !deliverable.isExpanded,
            };
          }
          return deliverable;
        }),
      })),
    });
  }

  @Action(EditPlan)
  updatePlan(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: EditPlan
  ) {
    ctx.patchState({
      ...payload,
    });
  }

  // Phase CRUD
  @Action(AddPhase)
  addPhase(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: AddPhase
  ) {
    const currentState = ctx.getState();
    const newPhase = {
      deliverables: [],
      isExpanded: false,
      status: StatusType.NOT_STARTED,
      sort_order: 0,
      progress: 0,
      deliverables_progress: {
        on_track: 0,
        off_track: 0,
        not_started: 0,
        at_risk: 0,
        canceled: 0,
        completed: 0,
      },
      is_deleted: 0,
      ...payload.phase,
    };

    ctx.patchState({
      phases: [...currentState.phases, newPhase],
    });
  }

  @Action(EditPhase)
  editPhase(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: EditPhase
  ) {
    const currentState = ctx.getState();
    ctx.patchState({
      phases: currentState.phases.map((phase) => {
        if (phase.id === payload.phase.id) {
          return {
            ...phase,
            ...payload.phase,
          };
        }
        return phase;
      }),
    });
  }

  @Action(DeletePhase)
  deletePhase(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: DeletePhase
  ) {
    const currentState = ctx.getState();
    ctx.patchState({
      phases: currentState.phases.filter(
        (phase) => phase.id !== payload.phaseId
      ),
    });
  }

  @Action(UpdatePhaseStatus)
  updatePhaseStatus(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: UpdatePhaseStatus
  ) {
    const currentState = ctx.getState();
    ctx.patchState({
      phases: currentState.phases.map((phase) => {
        if (phase.id === payload.phaseId) {
          return {
            ...phase,
            status: payload.status,
          };
        }
        return phase;
      }),
    });
  }

  // Deliverable CRUD

  @Action(CreateDeliverable)
  createDeliverable(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: CreateDeliverable
  ) {
    const currentState = ctx.getState();
    const currentPhase = currentState.phases.find(
      (phase) => phase.id === Number(payload.implementation_phase_id)
    );
    if (currentPhase) {
      currentPhase.deliverables.push(
        this.updateDeliverablePermissions(this.user.id, payload, currentPhase)
      );
    }
    ctx.patchState({
      phases: currentState.phases,
    });
  }

  @Action(EditDeliverable)
  editDeliverable(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: EditDeliverable
  ) {
    const currentState = ctx.getState();
    ctx.patchState({
      phases: currentState.phases.map((phase) => ({
        ...phase,
        deliverables: phase.deliverables.map((deliverable) => {
          if (deliverable.id === payload.id) {
            return {
              ...deliverable,
              ...payload,
            };
          }
          return deliverable;
        }),
      })),
    });
  }

  @Action(DeleteDeliverable)
  deleteDeliverable(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: DeleteDeliverable
  ) {
    const currentState = ctx.getState();
    const currentPhase = currentState.phases.find(
      (phase) => phase.id === payload.implementation_phase_id
    );
    if (currentPhase) {
      const deliverableIndex = currentPhase.deliverables.findIndex(
        (deliverable) => deliverable.id === payload.id
      );
      if (deliverableIndex !== -1) {
        currentPhase.deliverables.splice(deliverableIndex, 1);
      }
    }
    ctx.patchState({
      phases: currentState.phases,
    });
  }

  // Action Item CRUD

  @Action(CreateActionItem)
  createActionItem(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: CreateActionItem
  ) {
    const currentState = ctx.getState();
    const currentPhase = currentState.phases.find(
      (phase) => phase.id === payload.implementation_phase_id
    );
    if (currentPhase) {
      const currentDeliverable = currentPhase.deliverables.find(
        (deliverable) =>
          deliverable.id === payload.implementation_deliverable_id
      );
      if (currentDeliverable) {
        currentDeliverable.actionItems.push(payload);
        this.updateDeliverablePermissions(
          this.user.id,
          currentDeliverable,
          currentPhase
        );
      }
    }
    ctx.patchState({
      phases: currentState.phases,
    });
  }

  @Action(EditActionItem)
  editActionItem(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: EditActionItem
  ) {
    const currentState = ctx.getState();
    ctx.patchState({
      phases: currentState.phases.map((phase) => ({
        ...phase,
        deliverables: phase.deliverables.map((deliverable) => ({
          ...deliverable,
          actionItems: deliverable.actionItems.map((actionItem) => {
            if (actionItem.id === payload.id) {
              return {
                ...actionItem,
                ...payload,
              };
            }
            return actionItem;
          }),
        })),
      })),
    });
  }

  @Action(DeleteActionItem)
  deleteActionItem(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: DeleteActionItem
  ) {
    const currentState = ctx.getState();
    const currentPhase = currentState.phases.find(
      (phase) => phase.id === payload.implementation_phase_id
    );
    if (currentPhase) {
      const currentDeliverable = currentPhase.deliverables.find(
        (deliverable) =>
          deliverable.id === payload.implementation_deliverable_id
      );
      if (currentDeliverable) {
        const actionItemIndex = currentDeliverable.actionItems.findIndex(
          (actionItem) => actionItem.id === payload.id
        );
        if (actionItemIndex !== -1) {
          currentDeliverable.actionItems.splice(actionItemIndex, 1);
        }
      }
    }
    ctx.patchState({
      phases: currentState.phases,
    });
  }

  @Action(AddAssignment)
  addAssignment(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: AddAssignment
  ) {
    const currentState = ctx.getState();
    if (payload.for === 'implementation_phase') {
      const currentPhase = currentState.phases.find(
        (phase) => phase.id === payload.for_id
      );
      if (currentPhase) {
        currentPhase.assignments.push(payload.assignment);
        this.updatePhasePermissions(this.user.id, currentState.phases);
      }
    } else if (payload.for === 'implementation_deliverable') {
      const currentPhase = currentState.phases.find(
        (phase) => phase.id === payload.implementation_phase_id
      );
      if (currentPhase) {
        const currentDeliverable = currentPhase.deliverables.find(
          (deliverable) => deliverable.id === payload.for_id
        );
        if (currentDeliverable) {
          currentDeliverable.assignments.push(payload.assignment);
          this.updateDeliverablePermissions(
            this.user.id,
            currentDeliverable,
            currentPhase
          );
        }
      }
    } else if (payload.for === 'implementation_action_item') {
      const currentPhase = currentState.phases.find(
        (phase) => phase.id === payload.implementation_phase_id
      );
      if (currentPhase) {
        const currentDeliverable = currentPhase.deliverables.find(
          (deliverable) =>
            deliverable.id === payload.implementation_deliverable_id
        );
        if (currentDeliverable) {
          const currentActionItem = currentDeliverable.actionItems.find(
            (actionItem) => actionItem.id === payload.for_id
          );
          if (currentActionItem) {
            currentActionItem.assignments.push(payload.assignment);
          }
          this.updateDeliverablePermissions(
            this.user.id,
            currentDeliverable,
            currentPhase
          );
        }
      }
    }
    ctx.patchState({
      phases: currentState.phases,
    });
  }

  @Action(DeleteAssignment)
  deleteAssignment(
    ctx: StateContext<PlanDetailsItemStateModel>,
    { payload }: DeleteAssignment
  ) {
    const currentState = ctx.getState();
    if (payload.for === 'implementation_phase') {
      const currentPhase = currentState.phases.find(
        (phase) => phase.id === payload.for_id
      );
      if (currentPhase) {
        const assignmentIndex = currentPhase.assignments.findIndex(
          (assignment) => assignment.id === payload.assignment.id
        );
        if (assignmentIndex !== -1) {
          currentPhase.assignments.splice(assignmentIndex, 1);
        }
        this.updatePhasePermissions(this.user.id, currentState.phases);
      }
    } else if (payload.for === 'implementation_deliverable') {
      const currentPhase = currentState.phases.find(
        (phase) => phase.id === payload.implementation_phase_id
      );
      if (currentPhase) {
        const currentDeliverable = currentPhase.deliverables.find(
          (deliverable) => deliverable.id === payload.for_id
        );
        if (currentDeliverable) {
          const assignmentIndex = currentDeliverable.assignments.findIndex(
            (assignment) => assignment.id === payload.assignment.id
          );
          if (assignmentIndex !== -1) {
            currentDeliverable.assignments.splice(assignmentIndex, 1);
          }
          this.updateDeliverablePermissions(
            this.user.id,
            currentDeliverable,
            currentPhase
          );
        }
      }
    } else if (payload.for === 'implementation_action_item') {
      const currentPhase = currentState.phases.find(
        (phase) => phase.id === payload.implementation_phase_id
      );
      if (currentPhase) {
        const currentDeliverable = currentPhase.deliverables.find(
          (deliverable) =>
            deliverable.id === payload.implementation_deliverable_id
        );
        if (currentDeliverable) {
          const currentActionItem = currentDeliverable.actionItems.find(
            (actionItem) => actionItem.id === payload.for_id
          );
          if (currentActionItem) {
            const assignmentIndex = currentActionItem.assignments.findIndex(
              (assignment) => assignment.id === payload.assignment.id
            );
            if (assignmentIndex !== -1) {
              currentActionItem.assignments.splice(assignmentIndex, 1);
            }
          }
          this.updateDeliverablePermissions(
            this.user.id,
            currentDeliverable,
            currentPhase
          );
        }
      }
    }
    ctx.patchState({
      phases: currentState.phases,
    });
  }

  // initial passthrough all phases and children to check for permissions
  updatePhasePermissions(userId: number, phases: PhaseStateModel[]) {
    phases.forEach((phase) => {
      phase.assignments.forEach((assignment) => {
        const userIsAssignedToPhase =
          Number(assignment.user_id) === Number(userId);
        phase.canEdit = userIsAssignedToPhase;

        phase.deliverables.forEach((deliverable) => {
          deliverable.canEdit = userIsAssignedToPhase;
          deliverable.actionItems.forEach((actionItem) => {
            actionItem.canEdit = userIsAssignedToPhase;
          });
        });
      });

      phase.deliverables.forEach((deliverable) => {
        const assignedToDeliverable = deliverable.assignments.some(
          (delAssignment) => {
            if (Number(delAssignment.user_id) === Number(userId)) {
              deliverable.canEdit = true;
              return true;
            }
            return false;
          }
        );
        deliverable.actionItems.forEach((actionItem) => {
          if (assignedToDeliverable) {
            actionItem.canEdit = true;
          }
          actionItem.assignments.forEach((actAssignment) => {
            if (Number(actAssignment.user_id) === Number(userId)) {
              actionItem.canEdit = true;
            }
          });
        });
      });
    });
    return phases;
  }

  // for updating permissions on a single deliverable or action item
  updateDeliverablePermissions(
    userId: number,
    deliverable: DeliverableStateModel,
    phase: PhaseStateModel
  ) {
    const userIsAssignedToPhase = phase.assignments
      .map((assignment) => assignment.user_id)
      .includes(userId);

    deliverable.canEdit = userIsAssignedToPhase;
    deliverable.actionItems.forEach((actionItem) => {
      actionItem.canEdit = userIsAssignedToPhase;
    });

    const assignedToDeliverable = deliverable.assignments.some(
      (delAssignment) => {
        if (Number(delAssignment.user_id) === Number(userId)) {
          deliverable.canEdit = true;
          return true;
        }
        return false;
      }
    );

    deliverable.actionItems.forEach((actionItem) => {
      if (assignedToDeliverable) {
        actionItem.canEdit = true;
      }
      actionItem.assignments.forEach((actAssignment) => {
        if (Number(actAssignment.user_id) === Number(userId)) {
          actionItem.canEdit = true;
        }
      });
    });
    return deliverable;
  }
}
