<app-report-controller
  #pieChart
  chartId="reasons-for-absence-chart"
  [chartType]="[donutChartType]"
  yAxisLabel="Less Frequent Absence Types"
  [colorPalette]="chartColors"
  [tooltipTrigger]="'item'"
  [minPercent]="2"
  (origApiResponseEventOutput)="updateCountsFromOriginal($event)"
  [requestParams]="{
        dataset: 'fct_coaching_session',
        dimension: 'absence_type_name',
        measure: {
            type: 'count',
            field: '*',
        },      
        filters: reasonsForAbsenceFilters
    }">
  <app-absence-types-table
    [filters]="defaultFilters"
    [colors]="chartColors"
    [limitDataPresentation]="5"
    [externalData]="absenceTypeCounts"></app-absence-types-table>
</app-report-controller>
