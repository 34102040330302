import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { PresenceChannel } from 'pusher-js';
import { BehaviorSubject, Subscription, map } from 'rxjs';
import { ReportRequestParams } from 'src/app/common/dtos/reports.dto';
import { MOCK_USER } from 'src/app/common/state/user/user.mocks';
import { MOCK_STACK_BAR_OPTIONS_DTO } from 'src/app/common/utilities/mocks/reporting.mocks';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';

import { Intercom } from '@supy-io/ngx-intercom';
import { CompetencyRequestDTO } from 'src/app/private/shared/dtos/competencies.dto';
import {
  MOCK_COMPETENCY_BADGE_EARNED_DTO,
  MOCK_STRAND_BADGE_EARNED_DTO,
} from '../../../common/utilities/mocks/badge.mocks';
import { AvatarDTO } from '../../shared/components/avatar/avatar.dto';
import { avatarDTOFromPusher } from '../../shared/components/avatar/avatar.translators';
import { LeveledCompetencyModalComponent } from '../../shared/components/competency/leved-competency-modal/leveled-competency-modal.component';
import { PreviewCompetencyComponent } from '../../shared/components/competency/preview-competency-modal/preview-competency.component';
import { MOCK_FILE_LIST } from '../../shared/components/file-management/file-list/file-list.mocks';
import { coachingSessionsData } from '../../shared/components/reports/kpi-controller/kpi-card.mock';
import { ResourcePreviewDTO } from '../../shared/components/resources/resource-preview/resource-preview.dto';
import { MOCK_RESOURCE_LIST } from '../../shared/components/resources/resource-preview/resource-preview.mocks';
import { BadgeEarnedDTO } from '../../shared/dtos/badge.dto';
import { FileDTO } from '../../shared/dtos/file.dto';
import {
  ChartDrilldownType,
  ChartType,
} from '../../shared/enums/chart-type.enum';
import {
  AlertLocation,
  AlertService,
  AlertType,
} from '../../shared/services/alert/alert.service';
import { ConfettiService } from '../../shared/services/confetti/confetti.service';
import { PusherService } from '../../shared/services/pusher/pusher.service';
import { CoachDialogService } from '../../shared/services/smart-coach/coach-dialog/coach-dialog.service';
import { FEATURE_FLAGS } from '../../shared/services/unleash/unleash.helpers';
import { FetchAttendeeRubrics } from '../../shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from '../../shared/state/coaching-log/coaching-log.state';

@Component({
  selector: 'app-style-library-page',
  templateUrl: './style-library.component.html',
  styleUrls: ['./style-library.component.scss'],
})
export class StyleLibraryComponent implements OnInit, OnDestroy {
  @ViewChild('mscGroup') mscGroup: ElementRef;

  @ViewChild('mscMessage') mscMessage: ElementRef;

  @ViewChild('competencyPreviewModal')
  competencyPreviewModal: PreviewCompetencyComponent;

  coachingSessionsData = coachingSessionsData;

  files: FileDTO[] = [];

  subs: Subscription[] = [];

  attendees: AvatarDTO[];

  particlesVisible: boolean;

  channel: PresenceChannel;

  chartTypes = ChartType;

  chartDrilldownTypes = ChartDrilldownType;

  competencyModalLogId = 0;

  competencyModalCompetencyIds: number[] = [];

  competencyModalCompetencyId = 0;

  competencyModalUserIds: number[] = [];

  competencyModalUserId = 0;

  competencyData: CompetencyRequestDTO;

  competencyModalLogAttendeeRubrics$ = this.store.select(
    CoachingLogState.getAttendeeRubricsData
  );

  resources: ResourcePreviewDTO[] = MOCK_RESOURCE_LIST;

  mockFiles = MOCK_FILE_LIST;

  confettiLocation = 'top';

  mockUser = MOCK_USER;

  selectedDate = new BehaviorSubject<number>(1620000000);

  selectedDate$ = this.selectedDate.asObservable();

  selectedTimezone = new BehaviorSubject<TimezoneLinkBack>(
    TimezoneLinkBack.Central
  );

  selectedTimezone$ = this.selectedTimezone.asObservable();

  @ViewChild('competencyModal')
  competencyModal: LeveledCompetencyModalComponent;

  @Output() readonly fileUploads = new EventEmitter<FileDTO>();

  strandBadge: BadgeEarnedDTO = MOCK_STRAND_BADGE_EARNED_DTO;

  competencyBadge: BadgeEarnedDTO = MOCK_COMPETENCY_BADGE_EARNED_DTO;

  aprilSessionParams: ReportRequestParams = {
    dataset: 'fct_user_badge',
    measure: {
      type: 'count',
      field: '',
    },
    filters: [
      {
        codename: 'school_year',
        title: 'School Year',
        value: ['6'],
        displayName: 'School Year',
      },
      {
        codename: 'month_of_school_year',
        title: 'Month of School Year',
        value: ['10'],
        displayName: '',
      },
    ],
  };

  primaryAverageParams: ReportRequestParams = {
    dataset: 'fct_coaching_session',
    measure: {
      type: 'count_distinct',
      field: 'log_id',
    },
    filters: [
      {
        codename: 'is_coaching',
        value: ['1'],
      },
    ],
  };

  secondaryAverageParams: ReportRequestParams = {
    dataset: 'fct_coaching_session',
    measure: {
      type: 'count_distinct',
      field: 'user_id',
    },
    filters: [
      {
        codename: 'is_coaching',
        value: ['1'],
      },
    ],
  };

  showCreateUserModal = false;

  mscEnabled = false;

  stackedBarChartMockData = MOCK_STACK_BAR_OPTIONS_DTO;

  intercomLogId: number;

  constructor(
    private store: Store,
    private pusher: PusherService,
    private confettiService: ConfettiService,
    private smartcoachDialog: CoachDialogService,
    private featureFlagService: UnleashService,
    private alertService: AlertService,
    private intercom: Intercom
  ) {
    this.mscEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.mySmartCoach
    );
  }

  updateMembers() {
    this.attendees = Object.keys(this.channel.members.members).map((key) =>
      avatarDTOFromPusher(this.channel.members.members[key])
    );
  }

  ngOnInit() {
    this.channel = this.pusher.connectToPresenceChannel('page-style-library');
    this.channel.bind('pusher:subscription_succeeded', () => {
      this.updateMembers();
    });
    this.channel.bind('pusher:member_added', () => {
      this.updateMembers();
    });
    this.channel.bind('pusher:member_removed', () => {
      this.updateMembers();
    });

    this.subs.push(
      this.confettiService.showConfettiNotifier.subscribe((value) => {
        this.particlesVisible = value;
      })
    );

    this.competencyModalLogAttendeeRubrics$
      .pipe(map((filterFn) => filterFn(this.competencyModalLogId)))
      .subscribe((attendeeRubrics) => {
        if (attendeeRubrics) {
          this.competencyModalUserIds = attendeeRubrics.map(
            (attendeeRubric) => attendeeRubric.userId
          );
          this.competencyModalCompetencyIds = attendeeRubrics.flatMap(
            (attendeeRubric) =>
              attendeeRubric.rubrics.flatMap((rubric) =>
                rubric.competencyGroups.flatMap((competencyGroup) =>
                  competencyGroup.competencies.map(
                    (competency) => competency.id
                  )
                )
              )
          );
        } else {
          this.competencyModalCompetencyIds = [];
        }
      });
  }

  clearSmartCoachMessages() {
    this.smartcoachDialog.outputMessages.next([]);
  }

  addSmartCoachMessage() {
    this.smartcoachDialog.outputMessages.next([
      {
        message: this.mscMessage.nativeElement.value,
        group: this.mscGroup.nativeElement.value
          ? this.mscGroup.nativeElement.value
          : 'group1',
      },
    ]);
    this.mscMessage.nativeElement.value = '';
  }

  getCompetencyLogData() {
    this.store.dispatch(new FetchAttendeeRubrics(this.competencyModalLogId));
  }

  setCompetencyModalId(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    this.competencyModalLogId = parseInt(target.value);
  }

  setCompetencyModalCompetencyId(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    this.competencyModalCompetencyId = parseInt(target.value);
  }

  setCompetencyModalUserId(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    this.competencyModalUserId = parseInt(target.value);
  }

  setConfettiLocation(location: string) {
    this.confettiLocation = location;
  }

  cueConfetti() {
    this.confettiService.showConfetti(this.confettiLocation);
  }

  toggleCompetencyModal() {
    this.competencyModal.coachlogId = this.competencyModalLogId;
    this.competencyModal.competencyId = this.competencyModalCompetencyId;
    this.competencyModal.userId = this.competencyModalUserId;
    this.competencyModal.ngOnInit();
    this.competencyModal.modalShowing.next(
      !this.competencyModal.modalShowing.value
    );
  }

  handleCompetencySummaryEvent() {
    // This handle function should be repurposed
    // replace confetti with desired functionality
    this.cueConfetti();
  }

  openAlert(message: string) {
    // this.alertSerivce.showAlert 2nd and 3rd optional parameters defaults to primary and topcenter
    if (message === 'Primary Alert') {
      this.alertService.showAlert('Primary Alert');
    } else if (message === 'Secondary Alert') {
      this.alertService.showAlert(
        'Secondary Alert',
        AlertLocation.TOPRIGHT,
        AlertType.SECONDARY
      );
    } else if (message === 'Danger Alert') {
      this.alertService.showAlert(
        'Danger Alert',
        AlertLocation.BOTTOMCENTER,
        AlertType.DANGER
      );
    } else if (message === 'Info Alert') {
      this.alertService.showAlert(
        'Info Alert',
        AlertLocation.BOTTOMRIGHT,
        AlertType.INFO
      );
    } else if (message === 'Tertiary Alert') {
      this.alertService.showAlert(
        'Tertiary Alert',
        AlertLocation.CENTER,
        AlertType.TERTIARY
      );
    } else if (message === 'Neutral Alert') {
      this.alertService.showAlert(
        'Neutral Alert',
        AlertLocation.BOTTOMLEFT,
        AlertType.NEUTRAL
      );
    }
  }

  openPreviewCompetency() {
    this.competencyData = { rubricId: 4, standardId: 25 };
    this.competencyPreviewModal.openModal();
  }

  setIntercomLogId(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    this.intercomLogId = parseInt(target.value);
  }

  sendIntercomTest() {
    if (this.intercomLogId > 0) {
      this.intercom.trackEvent('completed-session', {
        log_id: this.intercomLogId,
      });
    } else {
      alert('Set a log id first');
    }
  }

  ngOnDestroy() {
    this.pusher.disconnectFromPresenceChannel('page-style-library');

    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
