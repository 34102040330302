import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { UserService } from 'src/app/common/state/user/user.service';
import { userFromDTO } from 'src/app/common/state/user/user.utilities';
import { ResourceFolderModelDTO } from '../../shared/dtos/resources.dto';
import { ResourcesBookmarkService } from '../../shared/services/resources/resources-bookmark.service';

@Component({
  selector: 'app-shared-resources-page',
  templateUrl: './shared-resources-page.component.html',
  styleUrl: './shared-resources-page.component.scss',
})
export class SharedResourcesPageComponent implements OnInit {
  userId: string | null;

  user: UserLiteDTO;

  folders: ResourceFolderModelDTO[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private resourcesBookmarkService: ResourcesBookmarkService
  ) {}

  ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get('id');
    if (this.userId) {
      this.getFolders(this.userId);
    }
    if (this.userId) {
      this.userService
        .fetchUserById(parseInt(this.userId))
        .subscribe((userDTO) => {
          this.user = userFromDTO(userDTO);
        });
    }
  }

  getFolders(userId: string) {
    this.resourcesBookmarkService
      .getUsersFolders(userId)
      .subscribe((response) => {
        this.folders = response.items;
      });
  }

  selectFolder(newfolderId: number) {
    this.router.navigate([
      `/resources/shared/${this.userId}/folder/${newfolderId}`,
    ]);
  }
}
