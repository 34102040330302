<app-modal
  #pdFilterModal
  (isDismissing)="closeModal()"
  (isOpening)="setInitialFilters()"
  [modalConfig]="{
    titleText: 'Filter Results',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <ng-container *ngIf="currentFilters">
    <div class="row datePickerRow noTop gy-3">
      <label class="form-label">Date Range:</label>
      <app-daterange-picker
        [fromDate]="currentFilters.from_date"
        [toDate]="currentFilters.to_date"
        (selectedFromDate)="$event && (currentFilters.from_date = $event)"
        (selectedToDate)="
          $event && (currentFilters.to_date = $event)
        "></app-daterange-picker>
    </div>
    <div *ngIf="context === 'normal'" class="row gy-3">
      <div class="col-md-12 gx-5">
        <label class="form-label">Keyword:</label>
        <input
          [(ngModel)]="currentFilters.keyword"
          type="text"
          class="form-control" />
      </div>
    </div>
    <div class="row gy-3">
      <div class="col-md-12 gx-5">
        <label class="form-label">Facilitator(s):</label>
        <app-user-select
          #facilitatorSelect
          *ngIf="user.district"
          [clearAfterSelection]="false"
          [isMulti]="true"
          [includeAllB2bDistricts]="true"
          [districtId]="isE2L ? null : user.district.id"
          [defaultUsers]="currentFilters.facilitators"
          (selectedUserList)="currentFilters.facilitators = $event" />
      </div>
    </div>
    <div class="row gy-3">
      <div class="col-md-12 gx-5">
        <label class="form-label">Type:</label>
        <ng-select
          #typeSelect
          id="type-select"
          (change)="handleTypeSelect($event)"
          placeholder="Select a type..."
          aria-label="pd-type-select">
          <ng-option value="Course">Course</ng-option>
          <ng-option value="Workshop">Workshop</ng-option>
          <ng-option value="Webinar">Webinar</ng-option>
        </ng-select>
      </div>
      <div class="col d-flex gx-5 mt-2">
        <ng-container *ngFor="let type of currentFilters.types">
          <span class="badge badge-item removable" (click)="removeType(type)"
            >{{ type }} &nbsp;<i class="bi bi-x"></i> </span
          >&nbsp;
        </ng-container>
      </div>
    </div>
    <div *ngIf="settings" class="row gy-3">
      <div class="col-md-12 gx-5">
        <label class="form-label">Category:</label>
        <ng-select
          #categorySelect
          (change)="handleCategorySelect($event); categorySelect.blur()"
          id="pd-category-select"
          placeholder="Select a category..."
          aria-label="pd-category-select">
          <ng-option
            *ngFor="let category of settings.categories"
            [value]="category.title">
            {{ category.title }}
          </ng-option>
        </ng-select>
      </div>
      <div class="col d-flex gx-5 mt-2">
        <ng-container *ngFor="let category of currentFilters.categories">
          <span
            class="badge badge-item removable"
            (click)="removeCategory(category)"
            >{{ category }} &nbsp;<i class="bi bi-x"></i> </span
          >&nbsp;
        </ng-container>
      </div>
    </div>
    <div class="row gy-3 mb-2">
      <div class="col-md-12 gx-5">
        <label class="form-label">Format</label>
        <div class="form-check">
          <input
            [(ngModel)]="currentFilters.virtual"
            type="checkbox"
            class="form-check-input"
            name="virtualCheckbox"
            id="virtual-checkbox" />
          <label class="form-check-label" for="virtualCheckbox">
            Virtual
          </label>
        </div>
        <div class="form-check">
          <input
            [(ngModel)]="currentFilters.onsite"
            type="checkbox"
            class="form-check-input"
            name="onsiteCheckbox"
            id="onsite-checkbox" />
          <label class="form-check-label" for="onsiteCheckbox"> Onsite </label>
        </div>
      </div>
    </div>
    <div class="row gy-3 mb-2">
      <div class="col-md-12 gx-5">
        <label class="form-label">Training Session Status</label>
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            name="openCheckbox"
            id="open-checkbox"
            disabled
            checked />
          <label class="form-check-label" for="openCheckbox"> Open </label>
        </div>
        <div class="form-check">
          <input
            [(ngModel)]="currentFilters.include_completed"
            type="checkbox"
            class="form-check-input"
            name="completedCheckbox"
            id="completed-checkbox" />
          <label class="form-check-label" for="completedCheckbox">
            Completed
          </label>
        </div>
      </div>
    </div>
  </ng-container>

  <div footerContent>
    <div class="d-flex justify-content-between">
      <button (click)="closeModal()" class="btn">Cancel</button>
      <button (click)="closeModal(true)" class="btn btn-primary">Apply</button>
    </div>
  </div>
</app-modal>
