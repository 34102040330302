<div class="container">
  <div class="row justify-content-between align-items-center mb-3">
    <div class="col-7">
      <h5 class="fw-700">Professional Development</h5>
    </div>
    <div class="col-5 text-end">
      <app-action-buttons />
    </div>
  </div>
  <div class="card border-0 px-4 py-2">
    <div *ngIf="transcriptData; else loading" class="card-body">
      <div *ngIf="isAdmin && currentUser.district" class="user-search mb-3">
        <div class="col-12 col-sm-6 col-lg-4 d-flex pb-3 align-items-center">
          <div class="fw-700 fs-18 me-2 lh-1">User:</div>
          <div class="w-100">
            <app-user-select
              [districtId]="isE2L ? null : currentUser.district.id"
              [defaultUsers]="transcriptUser ? [transcriptUser] : null"
              (selectedUser)="handleUserSearchOutput($event)" />
          </div>
        </div>
      </div>
      <div class="row justify-content-start">
        <div
          *ngIf="currentUser && currentUser.profile"
          class="d-flex justify-content-start col-12 col-sm-8 align-items-center">
          <app-avatar
            [profile]="transcriptUser.profile"
            [avatarSize]="'xxx-large'"
            [profileLinkActive]="false" />
          <div class="d-flex flex-column ms-3">
            <div class="fs-22">Transcript</div>
            <div class="fs-43 fw-700" id="profile-full-name">
              {{ transcriptUser.profile.first_name }}
              {{ transcriptUser.profile.last_name }}
            </div>
          </div>
        </div>
        <div
          class="col-12 col-sm-4 d-flex justify-content-end align-items-start">
          <div class="d-flex align-items-center">
            <span class="fw-700 me-2"> School Year: </span>
            <app-year-select
              (selectedYear)="handleSelectedYear($event)"
              #yearSelect />
          </div>
        </div>
      </div>
      <div class="transcript-table-container">
        <table
          *ngIf="transcriptData && transcriptData.length"
          class="table transcript-table mt-4 mb-0">
          <thead>
            <tr>
              <th style="width: 13%" (sort)="onSort($event)" sortable="date">
                Date
                <ng-container *ngIf="!loadingIcons">
                  <i class="bi bi-caret-down {{ sortIconTemp.date }}"></i>
                </ng-container>
              </th>
              <th style="width: 25%" (sort)="onSort($event)" sortable="title">
                Title
                <ng-container *ngIf="!loadingIcons">
                  <i class="bi bi-caret-down {{ sortIconTemp.title }}"></i>
                </ng-container>
              </th>
              <th style="width: 25%">Evidence</th>
              <th style="width: 25%" (sort)="onSort($event)" sortable="source">
                Source
                <ng-container *ngIf="!loadingIcons">
                  <i class="bi bi-caret-down {{ sortIconTemp.source }}"></i>
                </ng-container>
              </th>
              <th
                style="width: 10%"
                (sort)="onSort($event)"
                sortable="credit_type">
                Credits
                <ng-container *ngIf="!loadingIcons">
                  <i
                    class="bi bi-caret-down {{ sortIconTemp.credit_type }}"></i>
                </ng-container>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let transcript of transcriptData"
              style="vertical-align: middle">
              <td>
                {{
                  transcript.date
                    | showDateTimezone : "MMM d, yyyy" : undefined : false
                }}
              </td>
              <td class="event-link">
                <ng-container *ngIf="transcript.pd_session_id; else noSession">
                  <a
                    class="event-link"
                    ngbTooltip="Go to Session"
                    target="_blank"
                    href="learning/session/{{
                      transcript.pd_session_id
                    }}?user_id={{ transcript.user_id }}"
                    >{{ transcript.title }}</a
                  >
                </ng-container>
                <ng-template #noSession>
                  {{ transcript.title }}
                </ng-template>
              </td>
              <td>
                <ng-container *ngIf="transcript.evidence_link">
                  <a
                    class="evidence-link cursor-pointer"
                    ngbTooltip="View Evidence"
                    target="_blank"
                    (click)="viewFile(transcript.evidence_link)">
                    {{ transcript.evidence_title }}
                  </a>
                </ng-container>
              </td>
              <td>
                {{ transcript.source }}
                <ng-container *ngIf="transcript.source === 'Manual Entry'">
                  <button
                    class="btn btn-delete ps-1"
                    ngbTooltip="Delete manual entry"
                    type="button"
                    [autoClose]="'outside'"
                    [ngbPopover]="deleteEntryConfirmation"
                    [popoverTitle]="'Delete Manual Entry'"
                    #popover="ngbPopover">
                    <i class="bi bi-trash color-tertiary cursor-pointer"></i>
                  </button>
                  <ng-template #deleteEntryConfirmation>
                    Are you sure you want to delete this entry?
                    <div class="text-end mt-2">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm px-3 m-1"
                        (click)="popover.close()">
                        No
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm px-3 m-1"
                        (click)="
                          deleteManualEntry(transcript.id); popover.close()
                        ">
                        Yes
                      </button>
                    </div>
                  </ng-template>
                </ng-container>
              </td>
              <td class="fw-700">{{ transcript.credit_type }}</td>
              <td>{{ transcript.credit_value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!addEntryMode">
        <button
          ngbTooltip="Add manual entry"
          class="btn fw-700 mt-3"
          (click)="addEntryMode = !addEntryMode">
          + Add Entry
        </button>
      </div>
      <div *ngIf="addEntryMode" class="row mt-3">
        <div class="col-sm-3 col-0"></div>
        <div class="col-sm-4 col-12 d-flex flex-column">
          <label class="form-label" for="entry-datetime">Date</label>
          <app-datepicker
            id="entry-datetime"
            (dateSelectedUnixTimestamp)="
              addEntryPayload.date = $event[0].time / 1000
            "
            [timepicker]="false" />

          <label for="transcript-title" class="form-label">Title</label>
          <input
            type="text"
            class="form-control"
            id="transcript-title"
            aria-label="Transcript Title"
            [(ngModel)]="addEntryPayload.title"
            [style.border]="
              formSubmitted && !addEntryPayload.title ? '1px solid red' : ''
            " />
        </div>
        <div class="col-sm-5 col-12 d-flex flex-column">
          <label for="entry-evidence" class="form-label"
            >Evidence
            <i
              class="bi bi-info-circle-fill color-primary"
              ngbTooltip="Upload your certificate or proof of completion"></i>
          </label>
          <app-file-upload
            *ngIf="!attachment"
            (outputFiles)="handleFileOutput($event)"
            [uploadType]="uploadType"
            [style]="uploadStyle" />
          <app-file-list
            *ngIf="addEntryPayload.attachment && attachment"
            (deletedFile)="attachment = null; addEntryPayload.attachment = ''"
            [fileList]="[attachment]" />
          <label for="entry-credit" class="form-label">Credit</label>
          <div *ngIf="settings?.credits" class="row">
            <div class="col-sm-8 col-6 credit-type-select">
              <ng-select
                aria-label="event-credit-type"
                [clearable]="false"
                [(ngModel)]="addEntryPayload.credit_type"
                [ngClass]="{
                  invalid: formSubmitted && !addEntryPayload.credit_type
                }">
                <ng-option
                  *ngFor="let credit of settings.credits"
                  [value]="credit.title"
                  >{{ credit.title }}</ng-option
                >
              </ng-select>
            </div>
            <div class="col-sm-4 col-6">
              <input
                class="form-control credit-value-input"
                id="credit-value"
                aria-label="Credit Value"
                [(ngModel)]="addEntryPayload.credit_value"
                (ngModelChange)="onCreditValueChange($event)"
                pattern="[0-9]*"
                [style.border]="
                  formSubmitted && !addEntryPayload.credit_value
                    ? '1px solid red'
                    : ''
                " />
            </div>
          </div>
          <div class="text-end mt-3">
            <button class="btn btn-secondary me-2" (click)="clearManualEntry()">
              Cancel
            </button>
            <button class="btn btn-primary" (click)="addManualEntry()">
              Add Manual Entry
            </button>
          </div>
        </div>
      </div>
      <div class="row mt-3 pt-4 yearly-total-section px-2">
        <div class="col-12 col-md-6 col-xl-4 d-flex flex-column">
          <h5 class="fw-700">Yearly Totals</h5>
          <ng-container *ngFor="let total of yearlyTotals">
            <ng-container *ngIf="total.credit_type">
              <div class="row justify-content-between yearly-total">
                <div class="col fw-700">{{ total.credit_type }}</div>
                <div class="col text-end">{{ total.credit_amount }}</div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div
          class="col-12 col-md-6 col-xl-8 d-flex justify-content-center align-items-center text-center">
          <button
            class="btn btn-primary mt-3 mt-md-0"
            (click)="downloadTranscript()">
            <i class="bi bi-download"></i>
            Download Transcript
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="w-100" style="background-color: #fff; height: 700px">
    <app-loader></app-loader>
  </div>
</ng-template>
