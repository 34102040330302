import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CoachingSessionAttendeeDTO } from '../../dtos/coaching-session.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { AbsentReasonsAPIResponse } from '../../types/responses/coaching-log.responses';

@Component({
  selector: 'app-coaching-attendee',
  templateUrl: './coaching-attendee.component.html',
  styleUrls: ['./coaching-attendee.component.scss'],
})
export class CoachingAttendeeComponent implements OnInit, OnDestroy {
  @Input() attendee: CoachingSessionAttendeeDTO;

  @Input() isCoach = false;

  @Input() absentReasonsList: AbsentReasonsAPIResponse[];

  @Input() calendarDetails: boolean;

  @Input() avatarSize:
    | 'small'
    | 'medium'
    | 'medium-large'
    | 'large'
    | 'x-large'
    | 'xx-large'
    | 'xxx-large'
    | 'avatar-side-nav';

  @Output() readonly removedAttendee: EventEmitter<CoachingSessionAttendeeDTO> =
    new EventEmitter();

  subs: Subscription[] = [];

  selectedAbsentReason: number | null;

  trackByFn = CoachingAttendeeComponent.trackByFn;

  constructor(public coachingService: CoachingLogService) {}

  ngOnInit() {
    if (this.attendee.absentReason) {
      this.selectedAbsentReason = this.attendee.absentReason;
    }
  }

  deleteAttendee() {
    if (this.attendee.id) {
      this.coachingService.deleteAttendee(this.attendee).subscribe();
    }
    this.removedAttendee.emit(this.attendee);
  }

  savePresence() {
    const payload = { present: this.attendee.present };
    if (!this.attendee.present) {
      this.attendee.absentReason = null;
      this.selectedAbsentReason = null;
    }
    this.coachingService.updateAttendee(this.attendee.id, payload).subscribe();
  }

  saveAbsentReason() {
    const payload = { absent_reason: this.selectedAbsentReason };
    this.attendee.absentReason = this.selectedAbsentReason;
    this.coachingService.updateAttendee(this.attendee.id, payload).subscribe();
  }

  public static trackByFn(item: AbsentReasonsAPIResponse) {
    return item.id;
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
