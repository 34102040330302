import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { GradeDTO } from 'src/app/common/dtos/grade.dto';
import { ContentAreaDTO } from 'src/app/common/dtos/school.dto';
import { EditUserPayload } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { userFromDTO } from 'src/app/common/state/user/user.utilities';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { UploadStyles } from '../../shared/components/file-management/file-upload/file-upload.component';
import { FileDTO } from '../../shared/dtos/file.dto';
import { UploadType } from '../../shared/enums/upload-type.enum';
import { getIcon } from '../../shared/helpers/icon.utilities';
import { userDTOFromAPIResponse } from '../../shared/helpers/translators/user.translators';
import { AlertService } from '../../shared/services/alert/alert.service';
import { UserProfilePictureFileService } from '../../shared/services/file/user-profile-picture.file.service';

@Component({
  selector: 'app-user-settings-page',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsPageComponent {
  isLoading = false;

  submitLoading = false;

  user: User;

  newUserData: User;

  errorMessage: string;

  uploadStyle = UploadStyles.NONE;

  uploadType = UploadType.USER_PROFILE_PICTURE;

  form: FormGroup;

  formSubmitted = false;

  contentAreaOptions: ContentAreaDTO[];

  contentAreasPayload: ContentAreaDTO[] = [];

  gradeOptions: GradeDTO[];

  gradesPayload: GradeDTO[] = [];

  constructor(
    private store: Store,
    private userService: UserService,
    private alertService: AlertService,
    private userProfilePictureFileService: UserProfilePictureFileService,
    private formBuilder: FormBuilder
  ) {
    this.isLoading = true;
    this.userService.fetchUser().subscribe((res) => {
      if (res) {
        const userDto = userDTOFromAPIResponse(res.item);
        const userData = userFromDTO(userDto);
        this.user = userData;
        this.newUserData = deepCopy(this.user);
        this.userProfilePictureFileService.setUserId(this.user.id);
        this.isLoading = false;
        this.form = this.formBuilder.group({
          first_name: [this.user.profile.first_name, Validators.required],
          last_name: [this.user.profile.last_name, Validators.required],
          title: [this.user.profile.title, Validators.required],
          school: [this.user.school, Validators.required],
          grade_levels_served: [''],
          content_areas: [''],
        });
        if (this.user.grades && this.user.grades.length > 0) {
          this.gradesPayload = this.user.grades;
          this.f['grade_levels_served'].setValue(' ');
          this.f['grade_levels_served'].setValidators([Validators.required]);
        }
        if (this.user.contentAreas && this.user.contentAreas.length > 0) {
          this.contentAreasPayload = this.user.contentAreas;
          this.f['content_areas'].setValue(' ');
          this.f['content_areas'].setValidators([Validators.required]);
        }
      }
    });

    // load the grade options list
    this.userService.getGrades().subscribe((res) => {
      this.gradeOptions = res.items;
    });
    // load the content areas options list
    this.userService.getContentAreas().subscribe((res) => {
      this.contentAreaOptions = res.items;
    });
  }

  updateTextValue(event: string, label: string) {
    switch (label) {
      case 'First Name':
        this.newUserData.profile.first_name = event;
        break;
      case 'Last Name':
        this.newUserData.profile.last_name = event;
        break;
      case 'Title':
        this.newUserData.profile.title = event;
        break;
      default:
        break;
    }
  }

  updateProfilePicture(file: FileDTO) {
    this.user.profile = {
      ...deepCopy(this.user.profile),
      photo: file.location,
      photoUrl: getIcon(file.location),
    };
  }

  deleteProfilePicture() {
    this.userProfilePictureFileService.deleteProfilePicture().subscribe({
      error: (err) => {
        this.errorMessage = err.error.message;
        this.submitLoading = false;
      },
      complete: () => {
        this.user.profile = {
          ...deepCopy(this.user.profile),
          photo: '',
          photoUrl: '',
        };
      },
    });
  }

  get f() {
    return this.form.controls;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateMultiSelectValues(event: any, type: string) {
    const value = parseInt(event);
    switch (type) {
      case 'grade_levels_served':
        if (!this.gradesPayload.find((grade) => grade.id === value)) {
          const gradeInfo = this.gradeOptions.find(
            (option) => option.id === value
          );
          if (gradeInfo) {
            this.gradesPayload.push(gradeInfo);
          }
        }
        this.f['grade_levels_served'].setValue(' ');
        break;
      case 'content_areas':
        if (!this.contentAreasPayload.find((area) => area.id === value)) {
          const contentArea = this.contentAreaOptions.find(
            (option) => option.id === value
          );
          if (contentArea) {
            this.contentAreasPayload.push(contentArea);
          }
        }
        this.f['content_areas'].setValue(' ');
        break;
      default:
        break;
    }
  }

  removeMultiSelectValue(type: string, value: ContentAreaDTO | GradeDTO) {
    switch (type) {
      case 'contentArea':
        this.contentAreasPayload = this.contentAreasPayload?.filter(
          (area) => area.id !== value.id
        );
        if (this.contentAreasPayload.length === 0) {
          this.f['content_areas'].setValue('');
        }
        break;
      case 'grade':
        this.gradesPayload = this.gradesPayload?.filter(
          (grade) => grade.id !== value.id
        );
        if (this.gradesPayload.length === 0) {
          this.f['grade_levels_served'].setValue('');
        }
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line consistent-return
  updateUser() {
    this.submitLoading = true;
    this.formSubmitted = true;
    if (this.form.invalid) {
      this.submitLoading = false;
      return false;
    }
    const newUserDataPayload: EditUserPayload = {
      profile: {
        first_name: this.newUserData.profile.first_name,
        last_name: this.newUserData.profile.last_name,
        title: this.newUserData.profile.title,
      },
    };

    const servicesLoading: boolean[] = [];
    this.userService.updateUser(this.user.id, newUserDataPayload).subscribe({
      error: (err) => {
        this.errorMessage = err.error.message;
        this.submitLoading = false;
        this.formSubmitted = false;
      },
      next: (res) => {
        servicesLoading.push(true);
        this.user.fullName = `${res.profile.first_name} ${res.profile.last_name}`;
        if (servicesLoading.length === 3) {
          this.alertService.showAlert('User Settings Saved');
          this.submitLoading = false;
          this.formSubmitted = false;
        }
      },
    });

    if (this.gradesPayload.length > 0) {
      this.userService
        .updateGrades(this.user.id, this.gradesPayload)
        .subscribe({
          error: (err) => {
            this.errorMessage = err.error.message;
            this.submitLoading = false;
            this.formSubmitted = false;
          },
          next: () => {
            servicesLoading.push(true);
            if (servicesLoading.length === 3) {
              this.alertService.showAlert('User Settings Saved');
              this.submitLoading = false;
              this.formSubmitted = false;
            }
          },
        });
    } else {
      servicesLoading.push(true);
    }

    if (this.contentAreasPayload.length > 0) {
      this.userService
        .updateContentAreas(this.user.id, this.contentAreasPayload)
        .subscribe({
          error: (err) => {
            this.errorMessage = err.error.message;
            this.submitLoading = false;
            this.formSubmitted = false;
          },
          next: () => {
            servicesLoading.push(true);
            if (servicesLoading.length === 3) {
              this.alertService.showAlert('User Settings Saved');
              this.submitLoading = false;
              this.formSubmitted = false;
            }
          },
        });
    } else {
      servicesLoading.push(true);
    }
  }
}
